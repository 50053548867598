import React, { useCallback, useEffect, useState } from "react";
import VisitStepAccordion from "components/visitStepAccordion/VisitStepAccordion";
import Header from "components/header/Header";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { pedStepsData } from "./pedStepsData";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import useModal from "hooks/useModal";
import usePatients from "hooks/visit/usePatients";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { ped_main_steps, speciality, step_status } from "helpers/enum";
import useIncompleteData from "hooks/visit/useIncompleteData";
import { useApiMutation } from "hooks/useApiMutation";
import { discardVisit } from "api/actions/VisitActions";
import usePopulatePedData from "hooks/visit/usePopulatePedData";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import ProviderWithPercentage from "components/providerWithPercentage/ProviderWithPercentage";
import LoaderComponent from "components/loader/LoaderComponent";
import Alert from "components/alerts/Alert";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import { setUserDetails } from "reducers/currentUserReducer";
import { useCurrentUser } from "hooks/useCurrentUser";
import BasicDetailsForm from "../common/BasicDetailsForm";

function PedVisitSteps() {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { mainSteps, subSteps } = usePedVisitFlow();
  const {
    getPatients,
    patientsList,
    fetchingPatients,
    getCurrentPatient,
  } = usePatients();
  const { userDetails } = useCurrentUser();
  const {
    // pcDoctorDetails,
    isInactivePcProviderLicense,
    isInactivePcProvider,
  } = useDataFromUserDetails();
  const {
    fetchIncompleteData,
    fetchingIncompleteData,
    incompleteData,
  } = useIncompleteData();
  const { mutate: callDiscardVisit, loading: discardingVisit } = useApiMutation(
    discardVisit
  );

  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );

  const [showBasicDetails, setShowBasicDetails] = useState(false);

  useEffect(() => {
    if (currentPatient) {
      setShowBasicDetails(!(currentPatient?.height && currentPatient?.weight));
    }
  }, [currentPatient]);

  function openBasicDetailsModal() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <BasicDetailsForm
          onClose={() => {
            onClose();
            window.location.reload();
          }}
          currentPatient={currentPatient}
          patientType={
            currentPatient?.relationship_with_account === "pappa"
              ? "young"
              : "old"
          }
          currentSpeciality={speciality.ped}
        />
      ),
      data: null,
    });
  }

  const isAllStepsCompleted =
    Object.keys(ped_main_steps).filter(
      (el) => mainSteps?.[el]?.status !== step_status.completed
    )?.length === 0;

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(pedVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.goBack();
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, history, incompleteData?.id, userDetails]);

  useEffect(() => {
    getPatients();
    fetchIncompleteData(speciality.ped);
  }, [getPatients, fetchIncompleteData]);

  useEffect(() => {
    if (
      incompleteData &&
      !fetchingIncompleteData &&
      !discardingVisit &&
      (isInactivePcProviderLicense || isInactivePcProvider)
    ) {
      setShowModal({
        state: true,
        children: (_, onClose) => (
          <Alert
            onPrimaryButtonClick={() => {
              onClose();
              confirmDeleteVisit();
            }}
            onSecondaryButtonClick={() => {
              onClose();
              history.replace({
                pathname: "/home",
              });
            }}
            secondaryButtonText="Cancel"
            primaryButtonText="Delete visit"
            heading="Couldn't proceed!"
            message="Your primary care visit intake is already in progress, to continue submitting the visit, please switch to an active primary care provider or to submit a new dermatology visit, please delete this visit."
          />
        ),
      });
    }
  }, [
    incompleteData,
    history,
    discardingVisit,
    fetchingIncompleteData,
    isInactivePcProvider,
    isInactivePcProviderLicense,
    setShowModal,
    confirmDeleteVisit,
  ]);

  usePopulatePedData({
    incompleteData,
    patientsList,
  });

  if (
    fetchingIncompleteData ||
    fetchingPatients ||
    discardingVisit ||
    !incompleteData ||
    !patientsList
  ) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
        }}
      />
    );
  }

  function deleteVisit() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  }

  function getMAText() {
    if (isAllStepsCompleted) {
      return "Thank you for completing the medical intake. You can review everything below, before proceeding to the payment.";
    } else {
      return "Please provide us with the following details. Make sure you tell us about the issue as much as possible.";
    }
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="New Visit"
        rightActionView={
          incompleteData && Object.keys(incompleteData)?.length ? (
            <p
              onClick={deleteVisit}
              className="text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer"
            >
              Delete
            </p>
          ) : null
        }
      />
      <div
        className={`overflow-y-auto ${
          isAllStepsCompleted
            ? "h-eliminateHeaderWithFooter"
            : "h-eliminateHeader"
        } wrapper bg-white`}
      >
        <div className="py-8">
          <MedicalAssistant xOffset={30} content={<p>{getMAText()}</p>} />
        </div>
        <ProviderWithPercentage
          doctorDetails={{
            provider_name: incompleteData?.provider_name,
            provider_id:
              incompleteData?.primary_care_steps?.main_steps
                ?.main_select_slot === "completed"
                ? incompleteData?.provider_id
                : null,
            provider_photo_url: incompleteData?.provider_photo_url,
            speciality: 2,
          }}
          percentage={incompleteData?.meta?.progress_percentage ?? 0}
        />
        <div className="min-h-full">
          <VisitStepAccordion
            data={pedStepsData(mainSteps, subSteps)}
            onClickSubStep={(key) => {
              dispatch(
                pedVisitFlowReducers.currentScreen({
                  value: key,
                })
              );
              history.push(`${url}/new-visit`);
            }}
          />
        </div>
      </div>
      {isAllStepsCompleted ? (
        <BottomFixedButton
          label="Next"
          onClick={() => {
            if (showBasicDetails) {
              openBasicDetailsModal();
            } else {
              history?.push("/visit/primary-care/terms_and_conditions");
            }
          }}
        />
      ) : null}
    </div>
  );
}

export default PedVisitSteps;
