import Images from "assets/images";
import classNames from "classnames";
import { snakeCase } from "lodash";
import { useHistory } from "react-router-dom";
const { trackEvent } = require("helpers/analytics");

function SimpleHeader({
  title,
  showBackButton,
  onBackButtonClick,
  rightActionView,
  wrapper = false,
  className,
  parentClass,
}) {
  const history = useHistory();
  return (
    <div
      className={classNames(
        "bg-white border-b border-solid border-borderColor sticky top-0 z-50 h-28",
        parentClass
      )}
    >
      <div
        className={classNames(
          "flex items-center h-full px-12 py-0 ml-2 md:mr-32 justify-between",
          wrapper ? "wrapper-lg" : "",
          className
        )}
      >
        {(showBackButton || onBackButtonClick) && (
          <img
            onClick={() => {
              onBackButtonClick ? onBackButtonClick() : history.goBack();
              title && trackEvent(`back_icon_${snakeCase(title)}`);
            }}
            className="flex-none object-contain w-10 cursor-pointer"
            src={Images.arrowBack}
            alt="back"
          />
        )}
        {
          <h2
            className={
              "flex-grow flex justify-center my-0 mx-auto font-bold text-2.5xl md:text-3xl text-indigo"
            }
          >
            {title && title}
          </h2>
        }
        {rightActionView && (
          <div className="flex justify-end flex-none">{rightActionView}</div>
        )}
      </div>
    </div>
  );
}
export default SimpleHeader;
