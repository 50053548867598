import React, { useEffect, useRef, useState } from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import { visitConcerns } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useDispatch } from "react-redux";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import useUpdatePrimaryCareVisit from "hooks/visit/useUpdatePrimaryCareVisit";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { primary_care_main_steps, primary_care_sub_steps } from "helpers/enum";
import { OtherConditionSchema } from "helpers/validationSchema";
import useVisitStepChange from "hooks/useVisitStepChange";
import scrollToBottom from "helpers/scrollToBottom";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { checkAlreadyCompleted } from "helpers/setPrimaryCareStepStatus";

function SelectConditionForPrimaryCare() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const { incompleteData, subSteps, patientsList } = usePrimaryCareVisitFlow();
  const { getCurrentPatient } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[primary_care_sub_steps.select_condition].value;

  const { updateVisit } = useUpdatePrimaryCareVisit();

  const [selected, setSelected] = useState(null);
  const [primaryCareConditionList, setPrimaryCareConditionList] = useState([]);

  const {
    payload: visitConcernsPayload,
    loading: gettingConcerns,
    query: queryConcerns,
  } = useApiQuery(visitConcerns(2), false);

  useEffect(() => {
    if (
      currentScreenValueFromStore?.length &&
      primaryCareConditionList?.length
    ) {
      if (
        primaryCareConditionList?.find(
          (el) =>
            el?.toLowerCase() === currentScreenValueFromStore?.toLowerCase()
        )
      ) {
        setSelected(currentScreenValueFromStore);
      } else {
        setSelected(
          primaryCareConditionList?.[primaryCareConditionList?.length - 1]
        );
        scrollToBottom();
      }
    }
  }, [currentScreenValueFromStore, primaryCareConditionList]);

  useEffect(() => {
    if (selected?.length) {
      if (
        selected ===
        primaryCareConditionList?.[primaryCareConditionList?.length - 1]
      ) {
        scrollToBottom();
      }
      dispatch(
        primaryCareVisitFlowReducers.select_condition({
          isValid: true,
        })
      );
    } else {
      dispatch(
        primaryCareVisitFlowReducers.select_condition({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected, primaryCareConditionList]);

  useEffect(() => {
    queryConcerns();
  }, [queryConcerns]);

  useEffect(() => {
    if (visitConcernsPayload?.visit_converns?.length) {
      setPrimaryCareConditionList(
        visitConcernsPayload?.visit_converns?.map((el) => el?.title)
      );
    }
  }, [visitConcernsPayload]);

  useEffect(() => {
    if (subSteps?.select_patient?.value === "Me") {
      dispatch(
        primaryCareVisitFlowReducers.select_condition({
          prevScreen: primary_care_sub_steps.select_patient,
        })
      );
    } else if (subSteps?.select_patient?.value === "My Child") {
      dispatch(
        primaryCareVisitFlowReducers.select_condition({
          prevScreen: primary_care_sub_steps.select_child,
        })
      );
    } else if (subSteps?.select_patient?.value === "Someone else") {
      dispatch(
        primaryCareVisitFlowReducers.select_condition({
          prevScreen: primary_care_sub_steps.select_someone_else,
        })
      );
    }
  }, [dispatch, subSteps]);

  function onRightButtonClick(_, nextAnimation) {
    if (
      selected ===
      primaryCareConditionList?.[primaryCareConditionList?.length - 1]
    ) {
      formRef?.current?.handleSubmit();
      nextAnimationRef.current = nextAnimation;
    } else {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            skin_issue: selected,
            primary_care_steps: {
              main_steps: {
                ...incompleteData?.primary_care_steps?.main_steps,
                [primary_care_main_steps.visit_details]: "completed",
                [primary_care_main_steps.main_select_slot]: checkAlreadyCompleted(
                  {
                    screen_name: primary_care_main_steps.visit_details,
                    primary_care_steps: incompleteData?.primary_care_steps,
                    step_placement: "main_steps",
                  }
                ),
              },
              sub_steps: {
                ...incompleteData?.primary_care_steps?.sub_steps,
              },
            },
          },
        },
        change_current_screen: true,
        screen_to_complete: primary_care_sub_steps.select_condition,
        callBack: () => {
          dispatch(
            primaryCareVisitFlowReducers.select_condition({
              value: selected,
            })
          );
          nextAnimation();
        },
      });
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          skin_issue: values?.other_condition,
          primary_care_steps: incompleteData?.primary_care_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: primary_care_sub_steps.select_condition,
      callBack: () => {
        dispatch(
          primaryCareVisitFlowReducers.select_condition({
            value: values?.other_condition,
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  function isLast() {
    return (
      primaryCareConditionList?.length &&
      selected &&
      primaryCareConditionList?.[primaryCareConditionList?.length - 1] ===
        selected
    );
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Thank you, {currentPatient?.first_name}. Now, what is your main
            concern today?
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        {gettingConcerns ? (
          <div className={"h-40 flex items-center justify-center"}>
            <CSSLoader className={"w-16 h-16"} color={"orange"} />
          </div>
        ) : (
          <RadioButtonCard
            options={primaryCareConditionList}
            onSelect={setSelected}
            selected={selected}
          />
        )}
      </Card>
      {isLast() ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              innerRef={formRef}
              onSubmit={onFormSubmit}
              initialValues={{
                other_condition:
                  currentScreenValueFromStore &&
                  primaryCareConditionList?.find(
                    (el) =>
                      el?.toLowerCase() !==
                      currentScreenValueFromStore?.toLowerCase()
                  )
                    ? currentScreenValueFromStore
                    : "",
              }}
              validationSchema={OtherConditionSchema}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_condition"}
                    name={"other_condition"}
                    label="Type your concern"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default SelectConditionForPrimaryCare;
