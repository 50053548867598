import React from "react";

import Images from "assets/images";

const DownloadAppSection = ({ userPlatform }) => {
  return (
    <div className="sticky top-0 flex items-center justify-between w-full px-10 py-6 border-b md:hidden bg-backgroundWhite border-grey-600">
      <h3 className="text-2xl font-medium text-black">Download our app</h3>
      {userPlatform === "android" ? (
        <a
          className="flex flex-row px-6 py-3 space-x-4 no-underline bg-black rounded-full "
          href="https://play.google.com/store/apps/details?id=com.skymd.online_doctor_consultation"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-7 h-7"
            src="https://d1xtxvtxcc0yfp.cloudfront.net/eyJidWNrZXQiOiJza3ltZC13ZWItaW1hZ2VzIiwia2V5IjoiR29vZ2xlX1BsYXlfTG9nby5wbmcifQ=="
            alt="Google Play Store"
          />
          <p className="text-2xl font-medium text-white">4.7</p>
          <img className="w-7 h-7" src={Images.starFilled} alt="Star rating" />
        </a>
      ) : (
        <a
          className="flex flex-row px-6 py-3 space-x-4 no-underline bg-black rounded-full"
          href="https://itunes.apple.com/us/app/skymd-your-dermatologist-on/id963855994?ls=1&amp;mt=8"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-7 h-7"
            src="https://d1xtxvtxcc0yfp.cloudfront.net/eyJidWNrZXQiOiJza3ltZC13ZWItaW1hZ2VzIiwia2V5IjoiQXBwbGVfTG9nby5wbmcifQ=="
            alt="Apple App Store"
          />
          <p className="text-2xl font-medium text-white">4.8</p>
          <img className="w-7 h-7" src={Images.starFilled} alt="Star rating" />
        </a>
      )}
    </div>
  );
};

export default DownloadAppSection;
