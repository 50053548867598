import React, { useEffect, useState } from "react";

import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useCurrentUser } from "hooks/useCurrentUser";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import { useHistory } from "react-router-dom";
import { ped_sub_steps } from "helpers/enum";
import useCreatePedVisit from "hooks/visit/useCreatePedVisit";
import useUpdatePedVisit from "hooks/visit/useUpdatePedVisit";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Button from "components/buttons/Button";
import AddChildModal from "../../common/AddChildModal";
import useModal from "hooks/useModal";
import usePatients from "hooks/visit/usePatients";

function SelectPatientForPed() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setShowModal } = useModal();

  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const { userDetails } = useCurrentUser();
  const { fetchingPatients, getPatients } = usePatients();
  const { incompleteData, patientsList, subSteps } = usePedVisitFlow();
  const currentScreenValueFromStore =
    subSteps[ped_sub_steps.select_patient].value;

  const { createNewVisit, creatingVisit } = useCreatePedVisit();
  const { updateVisit, updatingVisit } = useUpdatePedVisit();

  const [selected, setSelected] = useState(null);
  const [childAdded, setChildAdded] = useState(false);

  useEffect(() => {
    if (
      patientsList?.filter(
        (patient) =>
          patient?.relationship_with_account &&
          patient.relationship_with_account === "pappa"
      ).length === 1
    ) {
      setSelected(
        patientsList?.filter(
          (patient) =>
            patient?.relationship_with_account &&
            patient.relationship_with_account === "pappa"
        )[0]?.full_name
      );
    }
  }, [patientsList]);

  useEffect(() => {
    if (!fetchingPatients) {
      if (childAdded) {
        setSelected(
          patientsList
            ?.filter(
              (patient) =>
                patient?.relationship_with_account &&
                patient.relationship_with_account === "pappa"
            )
            .slice(-1)[0]?.full_name
        );
      }
      setChildAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childAdded, patientsList]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        pedVisitFlowReducers.select_patient({
          isValid: true,
          nextScreen: ped_sub_steps.select_condition,
        })
      );
    } else {
      dispatch(
        pedVisitFlowReducers.select_patient({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected]);

  function onRightButtonClick() {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            patient_id: patientsList?.find((el) => el?.full_name === selected)
              ?.id,
            pediatric_steps: incompleteData?.pediatric_steps,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            pedVisitFlowReducers.select_patient({
              value: selected,
            })
          );
          history.push("/visit/pediatrics/pricing");
        },
      });
    } else {
      createNewVisit({
        patient_id: patientsList?.find((el) => el?.full_name === selected)?.id,
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            pedVisitFlowReducers.select_patient({
              value: selected,
            })
          );
          history.push("/visit/pediatrics/pricing");
        },
      });
    }
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Hi{userDetails?.first_name ? `, ${userDetails?.first_name}` : " "}!
            Let's get started. Please tell me who the patient is?
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        <RadioButtonCard
          loading={creatingVisit}
          options={patientsList
            ?.filter(
              (patient) =>
                patient?.relationship_with_account &&
                patient.relationship_with_account === "pappa"
            )
            ?.map((el) => el?.full_name)}
          onSelect={setSelected}
          selected={selected}
        />
        <Spacer height="38" />
        <Button
          disabled={updatingVisit}
          onClick={() => {
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <AddChildModal
                  onClose={onClose}
                  onComplete={() => {
                    getPatients();
                    setChildAdded(true);
                  }}
                />
              ),
              data: null,
            });
          }}
          label="Add Child"
          outlined
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectPatientForPed;
