import React from "react";
import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useHistory } from "react-router-dom";
import moment from "moment";
import VerticalStepper from "components/verticalStepper/VerticalStepper";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";

function AppointmentConfirmation() {
  const history = useHistory();
  const { incompleteData } = usePrimaryCareVisitFlow();

  async function confirmAppointment(proceed) {
    if (proceed) {
      history.push("/visit/primary-care/complete-visit-details");
    } else {
      history.push("/home");
    }
  }

  return (
    <div className="h-screen bg-veryLightBlue overflow-hidden">
      <Header title="Appointment Confirmation" hideBackBtn={true} />
      <div className="overflow-y-auto h-eliminateHeaderWithFooter wrapper bg-white">
        <div className="px-12 pt-8">
          <MedicalAssistant
            content={
              <p>
                {incompleteData
                  ? `Congrats, ${incompleteData?.patient_first_name}! Your video
                appointment has been scheduled for ${moment(
                  incompleteData?.meta?.booking_info?.selectedDate,
                  "YYYY-MM-DD"
                ).format("MMMM DD, YYYY")}, at ${moment(
                      incompleteData?.meta?.booking_info?.slotInfo?.start_time,
                      "hh:mma"
                    ).format("LT")}.`
                  : "Congrats! Your video appointment has been scheduled."}
              </p>
            }
          />
          <Spacer height={20} />
          <h3 className="font-black text-2xl pb-4 tracking-wide">
            YOUR VISIT WITH
          </h3>
          <div className="rounded-4xl p-5 border-1/4 border-borderColor">
            <DoctorCard
              imageUrl={incompleteData?.provider_photo_url}
              name={incompleteData?.provider_name}
              speciality="Family Practice: Primary Care Physician"
              isActive={true}
              license={incompleteData?.license}
            />
          </div>
        </div>
        <div className="px-12">
          <Spacer height={20} />
          <h3 className="font-black text-2xl pb-4 tracking-wide">STATUS</h3>
          <VerticalStepper
            data={[
              {
                status: "completed",
                heading: "Appointment booked",
              },
              {
                status: "upcoming",
                subtitle: "Before your visit",
                heading: "Complete your medical intake",
              },
              {
                status: "upcoming",
                subtitle: "Up Next",
                heading: `Meet with your doctor on
                ${moment(
                  incompleteData?.meta?.booking_info?.selectedDate,
                  "YYYY-MM-DD"
                ).format("MMMM DD, YYYY")}, at 
                ${moment(
                  incompleteData?.meta?.booking_info?.slotInfo?.start_time,
                  "hh:mma"
                ).format("LT")}`,
              },
              {
                status: "upcoming",
                subtitle: "Visit completed",
                heading: "Receive Treatment plan",
              },
            ]}
          />
        </div>
      </div>
      <div className="fixed bottom-0 w-full">
        <div className="wrapper bg-white flex justify-between items-center space-x-4 px-4 py-4 md:space-x-8">
          <button
            className="flex items-center justify-center font-bold text-2xl p-8 focus:outline-none focus:ring-2 bg-lightGrey text-black rounded-6/5 md:text-3xl"
            onClick={() => {
              confirmAppointment(false);
            }}
          >
            Home
          </button>
          <button
            className="flex-1 flex items-center justify-center font-bold text-2xl text-white p-8 w-full bg-orange rounded-6/5 focus:outline-none focus:ring-2 md:text-3xl"
            onClick={() => {
              confirmAppointment(true);
            }}
          >
            Complete Medical Intake
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppointmentConfirmation;
