import React, { useEffect, useState } from "react";
import { getPCSymptoms } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import CSSLoader from "components/cssLoader/CSSLoader";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import { useDispatch } from "react-redux";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import useUpdatePedVisit from "hooks/visit/useUpdatePedVisit";
import useVisitStepChange from "hooks/useVisitStepChange";
import { ped_sub_steps } from "helpers/enum";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function SymptomsForPed() {
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);

  const dispatch = useDispatch();
  const { incompleteData, subSteps } = usePedVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[ped_sub_steps.symptoms].value;

  const {
    payload: symptomsFromApi,
    loading: gettingSymptoms,
    query: pcSymptomsQuery,
  } = useApiQuery(
    getPCSymptoms({
      gender: "male",
    }),
    false
  );
  const { updateVisit } = useUpdatePedVisit();

  useEffect(() => {
    pcSymptomsQuery();
  }, [pcSymptomsQuery]);

  useEffect(() => {
    if (currentScreenValueFromStore?.length && symptomsFromApi?.length) {
      setSelectedSymptoms(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, symptomsFromApi]);

  useEffect(() => {
    if (selectedSymptoms?.length) {
      dispatch(
        pedVisitFlowReducers.symptoms({
          isValid: true,
        })
      );
    } else {
      dispatch(
        pedVisitFlowReducers.symptoms({
          isValid: false,
        })
      );
    }
  }, [dispatch, selectedSymptoms]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          pediatric_symptoms: selectedSymptoms,
          pediatric_steps: incompleteData?.pediatric_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: ped_sub_steps.symptoms,
      callBack: () => {
        dispatch(
          pedVisitFlowReducers.symptoms({
            value: selectedSymptoms,
          })
        );
        nextAnimation();
      },
    });
  }

  function onSelectSymptom(value, currentCategory) {
    if (
      selectedSymptoms?.find(
        (categ) => categ?.category === currentCategory?.related_to
      )
    ) {
      let selectedSymptomsCopy = [...selectedSymptoms];
      setSelectedSymptoms(
        selectedSymptomsCopy
          ?.map((categ) => {
            if (categ?.category === currentCategory?.related_to) {
              if (categ?.symptoms?.includes(value)) {
                categ = {
                  ...categ,
                  symptoms: categ?.symptoms?.filter((val) => val !== value),
                };
              } else {
                categ = {
                  ...categ,
                  symptoms: [...categ?.symptoms, value],
                };
              }
            }
            return categ;
          })
          ?.filter((vl) => vl?.symptoms?.length)
      );
    } else {
      setSelectedSymptoms((prev) => [
        ...prev,
        { category: currentCategory?.related_to, symptoms: [value] },
      ]);
    }
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Please select ALL of the following symptoms you have experienced in
            the LAST TWO WEEKS.
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        {gettingSymptoms ? (
          <div className={"h-40 flex items-center justify-center"}>
            <CSSLoader className={"w-16 h-16"} color={"orange"} />
          </div>
        ) : (
          symptomsFromApi?.map((el, index) => {
            return (
              <div key={index}>
                <p
                  className={`text-2xl font-extrabold pl-3 ${
                    index !== 0 ? "py-10" : "pb-10"
                  } uppercase`}
                >
                  {el?.related_to}
                </p>
                <CheckBoxCard
                  options={el?.symptoms}
                  selected={
                    selectedSymptoms?.find(
                      (elem) => elem?.category === el?.related_to
                    )?.symptoms
                  }
                  onSelect={(value) => onSelectSymptom(value, el)}
                />
              </div>
            );
          })
        )}
      </Card>
    </StepScreenContainer>
  );
}

export default SymptomsForPed;
