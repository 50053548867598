import classNames from "classnames";
import { startCase } from "lodash";
import moment from "moment";
import { Icon } from "stories/Icons";
import ReadMoreText from "stories/ReadMoreText";
const {
  default: CircularImageComponent,
} = require("components/imageComponent/CircularImageComponent");

const PracticeUserCard = ({
  picture,
  name,
  speciality,
  gender,
  showLicense = true,
  licenseState,
  licenseNumber,
  licenseActive,
  ratingValue,
  ratingUserCount,
  showNetwork = true,
  inNetwork,
  reviewedOn,
  online,
  size = "large",
  viewProfile,
  videoMeet,
  latestReview,
  cta,
  className = "p-4 border border-gray-200 rounded-lg md:p-10 ",
}) => {
  return (
    <div className={classNames("w-full", className)}>
      <div className="flex w-full h-full">
        <div
          className={classNames(
            "flex flex-col  items-center",
            size === "large"
              ? "justify-center"
              : size === "responsive"
              ? "md:justify-center"
              : ""
          )}
        >
          <CircularImageComponent
            url={picture}
            online={online}
            videoMeet={videoMeet}
            size={size}
          />
          {viewProfile && (
            <button
              onClick={viewProfile}
              className="hidden pt-4 text-2xl font-medium md:block text-secondaryBlue-500"
            >
              View Profile
            </button>
          )}
        </div>
        <div
          className={classNames(
            "flex flex-col items-start justify-start md:justify-center pl-6 md:pl-4 2xl:pl-8 -py-4",
            size === "large" ? "space-y-12" : "space-y-2 md:space-y-4"
          )}
        >
          <div className="flex flex-col items-start space-y-2 md:space-y-4">
            <h2 className="text-2.5xl md:text-3xl font-bold">{name}</h2>
            <p className="text-xl font-medium md:text-2xl">{speciality}</p>
          </div>
          {viewProfile && (
            <button
              onClick={viewProfile}
              className="text-2xl font-medium md:hidden text-secondaryBlue-500"
            >
              View Profile
            </button>
          )}
          <div className="hidden md:block">
            <div className="flex flex-col items-start space-y-4">
              {gender && (
                <p className="text-xl font-medium text-themeBlack">
                  {"Gender: "}
                  <span className="text-black">{startCase(gender)}</span>
                </p>
              )}
              {reviewedOn && (
                <div className="text-xl font-medium text-themeBlack">
                  {"Reviewed this visit on "}
                  {moment(reviewedOn)?.format("DD MMM, YYYY")}
                </div>
              )}
              {showLicense && (
                <div className="flex items-center space-x-3">
                  <p className="text-xl font-medium text-themeBlack">
                    <span className="text-black">
                      {licenseState} License :{" "}
                    </span>
                    {licenseNumber}
                  </p>
                  {!licenseActive && (
                    <p
                      className="p-2 text-xl font-medium rounded-md text-errorRed bg-red"
                      style={{ backgroundColor: "#FFEBEB" }}
                    >
                      License Expired
                    </p>
                  )}
                </div>
              )}
              {showNetwork && (
                <p
                  className={classNames(
                    "flex items-center space-x-2 text-xl font-medium text-green",
                    inNetwork ? "text-green" : "text-errorRed"
                  )}
                >
                  <Icon name="shield" />{" "}
                  <span className={!inNetwork ? "text-errorRed" : "text-green"}>
                    {inNetwork ? "In-network •" : "Out-of-Network •"}
                  </span>
                  <span className="text-themeBlack">{"Health Insurance"}</span>
                </p>
              )}
              {ratingValue !== undefined && ratingValue > 0 && (
                <div className="flex items-center space-x-2">
                  <span className="flex mr-2">
                    {[...Array(5)].map((_, index) => (
                      <Icon
                        key={`overall-ratings-${index}`}
                        className={classNames(
                          "w-5 h-5",
                          Math.round(ratingValue) > index
                            ? "text-yellow-450"
                            : "text-grey-600"
                        )}
                        name="star"
                      />
                    ))}
                  </span>
                  <span className="text-xl font-medium">
                    {Number(ratingValue)?.toFixed(1)}
                  </span>
                  <span className="text-xl font-medium text-themeBlack">
                    {`(${ratingUserCount} verified ${
                      ratingUserCount > 1 ? "reviews" : "review"
                    })`}
                  </span>
                </div>
              )}
            </div>
            {latestReview && (
              <div className="hidden max-w-md md:block xl:max-w-xl">
                <h3 className="text-xl font-medium">Recent Patient Review</h3>
                <ReadMoreText limit={150} text={`"${latestReview}"`} />
              </div>
            )}
          </div>
          {reviewedOn && (
            <div className="text-xl font-medium md:hidden text-themeBlack">
              {"Reviewed this visit on "}
              {moment(reviewedOn)?.format("DD MMM, YYYY")}
            </div>
          )}
        </div>
        <div className="justify-end flex-grow hidden w-max md:flex">{cta}</div>
      </div>

      {/* For mobile */}
      {(gender || showLicense || showNetwork || cta) && (
        <div className="pt-4 md:hidden">
          <div className="flex flex-col items-start space-y-4">
            {gender && (
              <p className="text-xl font-medium text-themeBlack">
                {"Gender: "}
                <span className="text-black">{startCase(gender)}</span>
              </p>
            )}

            {showLicense && (
              <div className="flex items-center space-x-3">
                <p className="text-xl font-medium text-themeBlack">
                  <span className="text-black">{licenseState} License : </span>
                  {licenseNumber}
                </p>
                {!licenseActive && (
                  <p
                    className="p-2 text-xl font-medium rounded-md text-errorRed bg-red"
                    style={{ backgroundColor: "#FFEBEB" }}
                  >
                    License Expired
                  </p>
                )}
              </div>
            )}
            {showNetwork && (
              <p
                className={classNames(
                  "flex items-center space-x-2 text-xl font-medium text-green",
                  inNetwork ? "text-green" : "text-errorRed"
                )}
              >
                <Icon name="shield" />{" "}
                <span className={!inNetwork ? "text-errorRed" : "text-green"}>
                  {inNetwork ? "In-network •" : "Out-of-Network •"}
                </span>
                <span className="text-themeBlack">{"Health Insurance"}</span>
              </p>
            )}
            {ratingValue !== undefined && ratingValue > 0 && (
              <div className="flex items-center space-x-2">
                <span className="flex mr-2">
                  {[...Array(5)].map((_, index) => (
                    <Icon
                      key={`overall-ratings-${index}`}
                      className={classNames(
                        "w-5 h-5",
                        Math.round(ratingValue) > index
                          ? "text-yellow-450"
                          : "text-grey-600"
                      )}
                      name="star"
                    />
                  ))}
                </span>
                <span className="text-xl font-medium">
                  {Number(ratingValue)?.toFixed(1)}
                </span>
                <span className="text-xl font-medium text-themeBlack">
                  {`(${ratingUserCount} verified ${
                    ratingUserCount > 1 ? "reviews" : "review"
                  })`}
                </span>
              </div>
            )}
          </div>
          <div className="w-full py-4 md:hidden">
            {latestReview && (
              <div className="w-full">
                <h3 className="text-xl font-medium">Recent Patient Review</h3>
                <ReadMoreText limit={150} text={`"${latestReview}"`} />
              </div>
            )}
          </div>
          {cta}
        </div>
      )}
    </div>
  );
};
export default PracticeUserCard;
