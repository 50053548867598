import { primary_care_sub_steps } from "helpers/enum";
import ConfirmResidenceForPrimaryCare from "./basicDetails/ConfirmResidenceForPrimaryCare";
import SelectPatientForPrimaryCare from "./basicDetails/SelectPatientForPrimaryCare";
import IdentityConfirmationForPrimaryCare from "./basicDetails/IdentityConfirmationForPrimaryCare";
import SelectConditionForPrimaryCare from "./visitDetails/SelectConditionForPrimaryCare";
import SymptomsForPrimaryCare from "./visitDetails/SymptomsForPrimaryCare";
import SelectPharmacyForPrimaryCare from "./additionalDetails/SelectPharmacyForPrimaryCare";
import AdditionalInfo from "./additionalDetails/AdditionalInfo";
import SelectSlot from "./selectSlot/SelectSlot";
import SelectChildForPrimaryCare from "./basicDetails/SelectChildForPrimaryCare";
import SelectSomeoneElse from "./basicDetails/SelectSomeoneElseForPrimaryCare";

export const getPrimaryCareStepScreen = (currentScreen) => {
  switch (currentScreen) {
    case primary_care_sub_steps.select_patient:
      return <SelectPatientForPrimaryCare />;
    case primary_care_sub_steps.select_child:
      return <SelectChildForPrimaryCare />;
    case primary_care_sub_steps.select_someone_else:
      return <SelectSomeoneElse />;
    case primary_care_sub_steps.confirm_residence:
      return <ConfirmResidenceForPrimaryCare />;
    case primary_care_sub_steps.identity_confirmation:
      return <IdentityConfirmationForPrimaryCare />;
    case primary_care_sub_steps.select_condition:
      return <SelectConditionForPrimaryCare />;
    case primary_care_sub_steps.symptoms:
      return <SymptomsForPrimaryCare />;
    case primary_care_sub_steps.select_slot:
      return <SelectSlot />;
    case primary_care_sub_steps.select_pharmacy:
      return <SelectPharmacyForPrimaryCare />;
    case primary_care_sub_steps.additional_info:
      return <AdditionalInfo />;
    default:
      break;
  }
};
