import React, { useMemo, useEffect } from "react";
import { patientVisittedVisitDetailsScreenAction } from "api/actions/VisitActions";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import DermatologyVisitSummary from "../waitingRoom/visitFlow/dermatology/checkout/DermatologyVisitSummary";
import { useCurrentUser } from "hooks/useCurrentUser";
import PrimaryCareVisitSummary from "../waitingRoom/visitFlow/primaryCare/checkout/PrimaryCareVisitSummary";
import VerticalStepper from "components/verticalStepper/VerticalStepper";
import { getStepperData } from "helpers/getVisitCurrentStatus";
import { step_status, visit_status } from "helpers/enum";
import ReceiptView from "./ReceiptView";
import ImageComponent from "components/imageComponent/ImageComponent";
import moment from "moment";
import DiagnosisAccordion from "./DiagnosisAccordion";
import { useApiMutation } from "hooks/useApiMutation";
import { getRevisedInsuranceAction } from "api/actions/VisitActions";
import RevisedPaymentBlock from "../waitingRoom/visitBlock/RevisedPaymentBlock";
import { useApiQuery } from "hooks/useApiQuery";
import VisitDiagnosedDetails from "./otcFlow/VisitDiagnosedDetails";

function MedicalVisitDetails({ visitDetails }) {
  const { userDetails } = useCurrentUser();

  const { mutate: recordPatientVisitted } = useApiMutation(
    patientVisittedVisitDetailsScreenAction
  );

  const {
    payload: revisedInsuranceDetails,
    query: getRevisedInsuranceDetails,
  } = useApiQuery(getRevisedInsuranceAction(visitDetails?.id), false);

  useEffect(() => {
    if (visitDetails?.payment_method === "insurance") {
      getRevisedInsuranceDetails();
    }
  }, [visitDetails, getRevisedInsuranceDetails]);

  const isDiagnosed = useMemo(() => {
    if (
      visitDetails?.diagnosis &&
      Object.keys(visitDetails?.diagnosis).length
    ) {
      return true;
    } else {
      return false;
    }
  }, [visitDetails]);

  useEffect(() => {
    if (visitDetails && !visitDetails?.error) {
      if (
        visitDetails?.status !== null &&
        (visitDetails?.status === visit_status.pending ||
          visit_status === visit_status.complete_awaiting_ma)
      ) {
        recordPatientVisitted(visitDetails?.id);
      }
    }
  }, [visitDetails, recordPatientVisitted]);

  function getMAText(details) {
    switch (details?.status) {
      case visit_status.pending:
        return "your visit has been submitted, we will get your diagnosis shortly.";
      case visit_status.evaluated:
        return "your visit has been evaluated.";
      case visit_status.rejected:
        return "your visit could not be evaluated. You will not be charged for this visit";
      default:
        return "here are your visit details.";
    }
  }

  if (isDiagnosed) {
    return (
      <>
        <VisitDiagnosedDetails visitDetails={visitDetails} />
        <Spacer height={80} />
        {visitDetails?.diagnosis &&
        Object.keys(visitDetails?.diagnosis).length ? (
          <div className="wrapper flex bg-white sticky bottom-0 shadow-d-xl">
            <ReceiptView visitDetails={visitDetails} />
          </div>
        ) : null}
      </>
    );
  } else {
    return (
      <div className="h-screen bg-veryLightBlue overflow-hidden">
        {!visitDetails?.error ? (
          <div className="overflow-y-auto wrapper pt-10 bg-white h-eliminateHeader relative">
            <div className="px-12">
              <MedicalAssistant
                content={
                  <p>
                    {visitDetails?.patient_name?.replace(/\s+/g, " ")},{" "}
                    {getMAText(visitDetails)}
                  </p>
                }
              />
            </div>
            {visitDetails?.zoom_meeting && (
              <div className="p-12">
                <p className="text-2xl font-light leading-9">
                  {visitDetails?.provider_name} has invited you to join a secure
                  video call. To join the call, please click on the button
                  below.
                </p>
                <a
                  href={visitDetails?.zoom_meeting?.join_url}
                  className={
                    "inline-block font-bold text-2xl py-4 px-16 focus:ring-2 bg-orange text-white rounded-lg cursor-pointer no-underline mt-6"
                  }
                >
                  Join
                </a>
              </div>
            )}
            <div className="px-12">
              {visitDetails?.current_status && !isDiagnosed ? (
                <div>
                  {(revisedInsuranceDetails?.revised_insurance_line_items
                    ?.price_change_type === "revised" ||
                    revisedInsuranceDetails?.revised_insurance_line_items
                      ?.price_change_type === "unrevised" ||
                    revisedInsuranceDetails?.revised_insurance_line_items
                      ?.price_change_type === "unsupported_insurance") &&
                  !revisedInsuranceDetails?.revised_insurance_line_items
                    ?.price_change_confirmed_at ? (
                    <RevisedPaymentBlock
                      visitDetails={visitDetails}
                      practiceInsurance={
                        revisedInsuranceDetails?.practice_insurance
                      }
                      revisedInsuranceDetails={
                        revisedInsuranceDetails?.revised_insurance_line_items
                      }
                    />
                  ) : (
                    <div />
                  )}
                  <h2 className="font-black text-xl tracking-wide mt-10">
                    STATUS
                  </h2>
                  <Spacer height={16} />
                  {visitDetails?.speciality === "primary_care" ||
                  visitDetails?.speciality === "pediatric" ? (
                    <VerticalStepper
                      data={[
                        {
                          status: step_status.completed,
                          heading: "Appointment booked",
                          timeStamp: new Date(),
                          statusText: "",
                        },
                        {
                          status: step_status.completed,
                          heading: "Completed your medical intake",
                          timeStamp: new Date(),
                          statusText: "",
                        },
                        {
                          status: step_status.upcoming,
                          heading:
                            "Review of your medical intake by the doctor",
                        },
                        {
                          status: step_status.upcoming,
                          heading: `Meet with your doctor on ${moment(
                            visitDetails?.meta?.booking_info?.selectedDate,
                            "YYYY-MM-DD"
                          ).format("MMMM DD, YYYY")} at ${moment(
                            visitDetails?.meta?.booking_info?.slotInfo
                              ?.start_time,
                            "hh:mma"
                          ).format("LT")}
                  `,
                        },
                        {
                          status: step_status.upcoming,
                          subtitle: "Visit completed",
                          heading: "Receive Treatment plan",
                        },
                      ]}
                    />
                  ) : (
                    <VerticalStepper data={getStepperData({ visitDetails })} />
                  )}
                </div>
              ) : null}
              <h2 className="font-black text-xl tracking-wide mt-10">
                VISIT SUMMARY
              </h2>
            </div>
            <Spacer height={23} />
            {visitDetails?.speciality === "primary_care" ||
            visitDetails?.speciality === "pediatric" ? (
              <PrimaryCareVisitSummary
                readOnly
                data={{
                  ...visitDetails,
                  patient_photo_id: userDetails?.photo_id,
                }}
              />
            ) : (
              <DermatologyVisitSummary
                readOnly
                data={{
                  ...visitDetails,
                  patient_photo_id: userDetails?.photo_id,
                }}
              />
            )}
            <h2 className="font-black text-xl tracking-wide mt-10 uppercase px-12">
              Diagnosis and Treatment Plan
            </h2>
            <Spacer height={23} />
            <div className="bg-alternativeWhite">
              <div className="px-12">
                {visitDetails?.status === visit_status.pending ? (
                  <div className="py-6">
                    <h3 className="font-bold text-2xl">
                      Your visit is awaiting review
                    </h3>
                    <p className="font-light text-2xl mt-4">
                      Once your visit has been evaluated, you will receive email
                      with your diagnosis and treatment plan.
                    </p>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center py-6">
                      <ImageComponent url={visitDetails?.provider_photo_url} />
                      <div className="pl-8">
                        <h3 className="text-3xl font-semibold">
                          {visitDetails?.provider_name}
                        </h3>
                        <p className="text-xl font-light mt-2">
                          reviewed this visit on{" "}
                          {moment(visitDetails?.diagnosis?.diagnosed_at).format(
                            "DD MMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <DiagnosisAccordion visitDetails={visitDetails} />
                  </div>
                )}
              </div>
            </div>
            <Spacer height={80} />
            {visitDetails?.diagnosis &&
            Object.keys(visitDetails?.diagnosis).length ? (
              <div className="wrapper flex bg-white sticky bottom-0 shadow-d-xl">
                <ReceiptView visitDetails={visitDetails} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default MedicalVisitDetails;
