import React, { useEffect, useState, useRef, useCallback } from "react";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import moment from "moment";
import Spacer from "components/Spacer/Spacer";
import {
  checkInsuranceNetworkAction,
  checkInsuranceNetworkActionForDerm,
  getInsuranceProviders,
  getInsuranceProvidersForDerm,
} from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import {
  InsuranceDetailsSchema,
  excludeImageContainer,
} from "helpers/validationSchema";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useCurrentUser } from "hooks/useCurrentUser";
import FormikErrorFocus from "formik-error-focus";
import usePatients from "hooks/visit/usePatients";
import { linkInsuranceAction } from "api/actions/VisitActions";
import { input_types } from "helpers/enum";
import BirthInput from "components/inputs/BirthInput";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDispatch } from "react-redux";
import Button from "components/buttons/Button";
import SmallLoader from "components/loader/SmallLoader";
import { Icon } from "stories/Icons";
import ImageUploader from "components/imagePickerTile/NewImagePickerTile";
import { includes } from "lodash";

function InsuranceUpdateModal({
  onClose,
  insuranceDetails: patientInsurance,
  currentPatient,
  currentSpeciality,
  insuranceType = 1,
  setInsurancePay = () => {},
  isSecondary = false,
}) {
  const relations = [
    "Self",
    "Spouse",
    "Parent",
    "Child",
    "GrandParent",
    "Grandchild",
    "Sibling",
    "Aunt/Uncle",
    "Niece/Nephew",
    "Other",
  ];

  const formRef = useRef();

  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(
    patientInsurance?.insurance_company ?? ""
  );
  const { userDetails, userId } = useCurrentUser();
  const { showAlert } = useToastify();
  const {
    getPatients,
    fetchingPatients,
    patientsList: newPatientList,
  } = usePatients();

  // DOB Confirm Changes
  const dobInputRef = useRef();
  const [showDate, setShowDate] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const {
    loading: gettingInsuranceProvidersList,
    payload: insuranceProvidersList,
    query: getInsuranceProvidersList,
  } = useApiQuery(
    getInsuranceProviders(
      userDetails?.state,
      currentSpeciality,
      searchQuery,
      isSecondary
    ),
    false
  );
  const {
    loading: gettingInsuranceProvidersListForDerm,
    payload: insuranceProvidersListForDerm,
    query: getInsuranceProvidersListForDerm,
  } = useApiQuery(
    getInsuranceProvidersForDerm(
      userDetails?.state,
      currentSpeciality,
      searchQuery,
      userDetails?.derm_physician?.provider_id,
      isSecondary
    ),
    false
  );

  const {
    loading: checkingInsuranceInNetwork,
    payload: insuranceInNetwork,
  } = useApiQuery(
    checkInsuranceNetworkAction(currentSpeciality, currentPatient?.id),
    currentSpeciality === 1 ? false : true
  );

  const {
    loading: checkingInsuranceInNetworkForDerm,
    payload: insuranceInNetworkForDerm,
  } = useApiQuery(
    checkInsuranceNetworkActionForDerm(
      currentSpeciality,
      currentPatient?.id,
      userDetails?.derm_physician?.provider_id
    ),
    currentSpeciality === 1
  );

  const { mutate: callLinkInsuranceApi, loading } = useApiMutation(
    linkInsuranceAction
  );

  useEffect(() => {
    if (newPatientList) {
      onClose();
      window.location.reload();
    }
  }, [newPatientList, onClose]);

  async function onFormSubmit(values) {
    const formData = new FormData();
    const isOutofNetwork =
      values?.insurance_provider === "Other, I do not see my plan listed";
    formData.append("group_number", null);
    const modifiedInsuranceCompany =
      values?.insurance_provider === isOutofNetwork
        ? values?.other_insurance_company
        : values?.insurance_provider;
    if (isOutofNetwork) {
      formData.append("unsupported_insurance", 1);
    } else {
      formData.append("unsupported_insurance", 0);
    }
    if (modifiedInsuranceCompany) {
      formData.append(
        "insurance_company",
        values?.insurance_provider === "Other, I do not see my plan listed"
          ? values?.other_insurance_company
          : values?.insurance_provider
      );
    }
    if (
      values?.member_dob &&
      moment(values?.member_dob, "YYYY-MM-DD").format("YYYY/MM/DD")
    ) {
      formData.append(
        "member_dob",
        moment(values?.member_dob).format("YYYY/MM/DD")
      );
    }
    if (values?.member_name) {
      formData.append("member_name", values?.member_name);
    }
    if (values?.member_id) {
      formData.append("member_id", values?.member_id);
    }
    if (values?.relationship) {
      formData.append("relationship_to_patient", values?.relationship);
    }
    if (
      values?.insurance_card_images[0]?.file &&
      !values?.insurance_card_images[0]?.uri?.startsWith("https://")
    ) {
      formData.append(
        "insurance_front_cover_photo",
        values?.insurance_card_images[0]?.file
      );
    }
    if (
      values?.insurance_card_images[1]?.file &&
      !values?.insurance_card_images[1]?.uri?.startsWith("https://")
    ) {
      formData.append(
        "insurance_back_cover_photo",
        values?.insurance_card_images[1]?.file
      );
    }
    if (
      (patientInsurance?.insurance_company && currentPatient?.id) ||
      (insuranceType === 2 && currentPatient?.id)
    ) {
      formData.append("patient_id", currentPatient?.id);
    }
    formData.append("insurance_type", insuranceType);
    try {
      const result = await callLinkInsuranceApi({
        formData,
        accountId: userId,
      });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        showAlert(result?.payload?.message, "success");
        await getPatients();
        setInsurancePay();
        if (currentSpeciality === 2) {
          dispatch(
            primaryCareVisitFlowReducers.setPaymentMethod({
              value: "insurance",
            })
          );
        } else {
          dispatch(
            dermaVisitFlowReducers.setPaymentMethod({
              value: "insurance",
            })
          );
        }
      }
    } catch (error) {
      errorHandler(error?.message);
    }
  }
  function errorHandler(message) {
    showAlert(message, "error");
  }

  const getInitialInsuranceProvider = useCallback(() => {
    if (currentSpeciality === 1) {
      if (patientInsurance) {
        if (insuranceInNetworkForDerm?.in_network) {
          return patientInsurance?.insurance_company;
        } else {
          return "Other, I do not see my plan listed";
        }
      } else if (currentPatient?.signup_insurance) {
        if (insuranceInNetworkForDerm?.in_network) {
          return currentPatient?.signup_insurance?.name;
        } else {
          return "Other, I do not see my plan listed";
        }
      } else {
        return "";
      }
    } else {
      if (patientInsurance) {
        if (insuranceInNetwork?.in_network) {
          return patientInsurance?.insurance_company;
        } else {
          return "Other, I do not see my plan listed";
        }
      } else if (currentPatient?.signup_insurance) {
        if (insuranceInNetwork?.in_network) {
          return currentPatient?.signup_insurance?.name;
        } else {
          return "Other, I do not see my plan listed";
        }
      } else {
        return "";
      }
    }
  }, [
    currentSpeciality,
    currentPatient?.signup_insurance,
    insuranceInNetwork?.in_network,
    insuranceInNetworkForDerm?.in_network,
    patientInsurance,
  ]);

  function getInitialOtherInsurance() {
    if (currentSpeciality === 1) {
      if (patientInsurance) {
        const insurance = insuranceProvidersListForDerm?.insurances?.find(
          (ins) => ins.name === patientInsurance.insurance_company
        );
        if (insurance) {
          return "";
        } else {
          return patientInsurance.insurance_company;
        }
      } else {
        return "";
      }
    } else {
      if (patientInsurance) {
        const insurance = insuranceProvidersList?.insurances?.find(
          (ins) => ins.name === patientInsurance.insurance_company
        );
        if (insurance) {
          return "";
        } else {
          return patientInsurance.insurance_company;
        }
      } else {
        return "";
      }
    }
  }

  function getInitialMemberName() {
    if (patientInsurance) {
      return patientInsurance?.member_name;
    } else if (currentPatient?.first_name && currentPatient?.last_name) {
      return `${currentPatient?.first_name} ${currentPatient?.last_name}`;
    } else {
      return "";
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentSpeciality === 1) {
        if (searchQuery?.length > 2) {
          getInsuranceProvidersListForDerm();
        }
      } else {
        if (searchQuery?.length > 2) {
          getInsuranceProvidersList();
        }
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="flex items-center justify-center w-full h-full py-20 overflow-auto bg-black bg-opacity-50">
      <div className="h-full max-w-4xl md:w-2/5 rounded-xl">
        {checkingInsuranceInNetwork ||
        checkingInsuranceInNetworkForDerm ||
        fetchingPatients ? (
          <SmallLoader
            className={"rounded-xl"}
            showLoader={{
              state: true,
              message: fetchingPatients
                ? "Please wait."
                : "Retrieving insurance carriers.",
            }}
          />
        ) : (
          <Formik
            innerRef={formRef}
            validationSchema={InsuranceDetailsSchema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              insurance_provider: getInitialInsuranceProvider(),
              other_insurance_company: getInitialOtherInsurance(),
              member_name: getInitialMemberName(),
              relationship:
                patientInsurance?.relationship_to_patient ?? relations[0] ?? "",
              member_id: patientInsurance?.member_id ?? "",
              member_dob: patientInsurance?.member_dob ?? "",
              insurance_card_images: [
                {
                  label: "Front",
                  uri: patientInsurance?.insurance_front_photo?.url
                    ? patientInsurance?.insurance_front_photo?.url
                    : patientInsurance?.insurance_front_cover_photo
                    ? patientInsurance?.insurance_front_cover_photo
                    : "",
                  file: null,
                  data: null,
                },
                {
                  label: "Back",
                  uri: patientInsurance?.insurance_back_photo?.url
                    ? patientInsurance?.insurance_back_photo?.url
                    : patientInsurance?.insurance_back_cover_photo
                    ? patientInsurance?.insurance_back_cover_photo
                    : "",
                  file: null,
                  data: null,
                },
              ],
            }}
            onSubmit={onFormSubmit}
          >
            {({ values, setFieldValue, setFieldTouched, dirty, errors }) => (
              <div className="p-6 bg-white rounded-xl">
                <div className="flex justify-between mb-4 space-x-5 font-medium">
                  <h2 className="text-2xl">
                    Please, provide the below information for your medical chart
                    with our practice
                  </h2>
                  <button
                    disabled={loading}
                    type="button"
                    onClick={() => onClose()}
                  >
                    <Icon name={"close"} />
                  </button>
                </div>
                <Form>
                  <Input
                    type={input_types.SELECT}
                    placeholder="Select an insurance carrier"
                    name="insurance_provider"
                    label="Insurance Carrier"
                    id="insurance_provider"
                    customonchange={(data) => {
                      setFieldValue("member_name", "");
                      setFieldValue("relationship", relations[0]);
                      setFieldValue("member_id", "");
                      setFieldValue("member_dob", "");
                      setFieldValue("insurance_card_images", [
                        {
                          label: "Back",
                          uri: "",
                          file: null,
                          data: null,
                          id: null,
                        },
                        {
                          label: "Back",
                          uri: "",
                          file: null,
                          data: null,
                          id: null,
                        },
                      ]);
                      setSearchQuery(data);
                    }}
                    isLoading={
                      gettingInsuranceProvidersList ||
                      gettingInsuranceProvidersListForDerm
                    }
                    options={
                      currentSpeciality === 1
                        ? insuranceProvidersListForDerm?.insurances
                          ? [
                              ...insuranceProvidersListForDerm?.insurances?.map(
                                (el) => ({
                                  label: el?.name,
                                  value: el?.name,
                                })
                              ),
                              {
                                label: "Other, I do not see my plan listed",
                                value: "Other, I do not see my plan listed",
                              },
                            ]
                          : [
                              {
                                label: "Other, I do not see my plan listed",
                                value: "Other, I do not see my plan listed",
                              },
                            ]
                        : insuranceProvidersList?.insurances
                        ? [
                            ...insuranceProvidersList?.insurances?.map(
                              (el) => ({
                                label: el?.name,
                                value: el?.name,
                              })
                            ),
                            {
                              label: "Other, I do not see my plan listed",
                              value: "Other, I do not see my plan listed",
                            },
                          ]
                        : [
                            {
                              label: "Other, I do not see my plan listed",
                              value: "Other, I do not see my plan listed",
                            },
                          ]
                    }
                    disabled={false}
                  />
                  {values.insurance_provider ===
                  "Other, I do not see my plan listed" ? (
                    <>
                      <Spacer height={24} />
                      <Input
                        label={"Other Insurance Company"}
                        name="other_insurance_company"
                        id="other_insurance_company"
                      />
                    </>
                  ) : null}
                  <Spacer height={24} />
                  <Input
                    name="member_name"
                    label="Member Name"
                    id="member_name"
                  />
                  <Spacer height={24} />
                  <Input
                    type={input_types.DROPDOWN}
                    name="relationship"
                    label="Relationship of patient to member"
                    id="relationship"
                    options={relations}
                  />
                  <Spacer height={24} />
                  <Input name="member_id" label="Member ID" id="member_id" />
                  <Spacer height={24} />
                  {/* DOB Confirm Changes */}
                  <BirthInput
                    ref={dobInputRef}
                    name="member_dob"
                    label="Member DOB"
                    id="member_dob"
                    max={moment().format("YYYY-MM-DD")}
                    isOpen={openCalendar}
                    open={() => setOpenCalendar(true)}
                    close={() => setOpenCalendar(false)}
                    onBlur={() => {
                      if (dirty) {
                        setFieldTouched("dob", true);
                      }
                    }}
                    onChange={(event) => {
                      const date = event.target.value
                        ? moment(event.target.value).format("YYYY-MM-DD")
                        : "";
                      setFieldValue("member_dob", date);

                      if (date !== showDate) {
                        setShowDate(event.target.value ?? "");
                      }
                    }}
                  />
                  <Spacer height={24} />
                  {!includes(
                    excludeImageContainer,
                    values.insurance_provider
                  ) && (
                    <div>
                      <label className="text-2xl font-bold text-opacity-50 text-indigo">
                        Upload Photos of your Insurance Card
                      </label>
                      <p>
                        Kindly ensure you provide both front and back images to
                        complete the process.
                      </p>
                      <Spacer height={18} />
                      <div className="flex w-full space-x-8">
                        <ImageUploader
                          id="insurance_front_photo"
                          previewLabel="Front Image"
                          label={
                            <p>
                              <span className="cursor-pointer text-secondaryBlue-600">
                                Upload
                              </span>{" "}
                              a photos or drag and drop (front)
                            </p>
                          }
                          onImageUpload={(e) => {
                            let cardImagesCopy = [
                              ...values?.insurance_card_images,
                            ];
                            cardImagesCopy[0] = e;
                            setFieldValue(
                              "insurance_card_images",
                              cardImagesCopy
                            );
                          }}
                          imageUrl={
                            values.insurance_card_images[0]?.data ??
                            values.insurance_card_images[0]?.uri
                          }
                          handleDelete={() => {
                            let cardImagesCopy = [
                              ...values?.insurance_card_images,
                            ];
                            cardImagesCopy[0] = {
                              label: "Front",
                              uri: "",
                              file: null,
                              data: null,
                              id: null,
                            };
                            setFieldValue(
                              "insurance_card_images",
                              cardImagesCopy
                            );
                          }}
                        />
                        <ImageUploader
                          id="insurance_back_photo"
                          label={
                            <p>
                              <span className="cursor-pointer text-secondaryBlue-600">
                                Upload
                              </span>{" "}
                              a photos or drag and drop (back)
                            </p>
                          }
                          previewLabel="Back Image"
                          onImageUpload={(e) => {
                            let cardImagesCopy = [
                              ...values?.insurance_card_images,
                            ];
                            cardImagesCopy[1] = e;
                            setFieldValue(
                              "insurance_card_images",
                              cardImagesCopy
                            );
                          }}
                          imageUrl={
                            values.insurance_card_images[1]?.data ??
                            values.insurance_card_images[1]?.uri
                          }
                          handleDelete={() => {
                            let cardImagesCopy = [
                              ...values?.insurance_card_images,
                            ];
                            cardImagesCopy[1] = {
                              label: "Back",
                              uri: "",
                              file: null,
                              data: null,
                              id: null,
                            };
                            setFieldValue(
                              "insurance_card_images",
                              cardImagesCopy
                            );
                          }}
                        />
                      </div>
                      {errors.insurance_card_images?.length &&
                      (errors.insurance_card_images?.[0]?.uri ||
                        errors.insurance_card_images?.[1]?.uri) ? (
                        <p className="mt-2 text-base font-bold text-red">
                          {errors.insurance_card_images?.[0]?.uri ||
                            errors.insurance_card_images?.[1]?.uri}
                        </p>
                      ) : null}
                    </div>
                  )}

                  <FormikErrorFocus
                    align={"bottom"}
                    ease={"linear"}
                    duration={500}
                  />
                </Form>
                <Spacer height={24} />
                <Button
                  type="submit"
                  label={
                    patientInsurance ? "Update Insurance" : "Add Insurance"
                  }
                  onClick={() => formRef?.current?.handleSubmit()}
                  loading={loading}
                  className={"h-18"}
                />
              </div>
            )}
          </Formik>
        )}
        {showConfirm && (
          <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
            <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
              <p className="text-3xl font-bold">Confirm Date of Birth</p>
              <p className="text-2xl font-medium leading-9">
                You've entered {moment(showDate).format("MM-DD-YYYY")} as date
                of birth. Click CONFIRM to proceed or EDIT to change the date.
              </p>
              <div className="flex items-center justify-end space-x-4">
                <button
                  className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                  onClick={() => {
                    if (dobInputRef.current) {
                      dobInputRef.current.focus();
                    }
                    setOpenCalendar(true);
                    setShowConfirm(false);
                  }}
                >
                  Edit
                </button>
                <button
                  className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                  onClick={() => {
                    setShowConfirm(false);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* DOB Confirm Changes */}
    </div>
  );
}

export default InsuranceUpdateModal;
