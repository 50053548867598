import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Images from "assets/images";
import Placeholder from "components/placeholders/Placeholder";
import { speciality } from "helpers/enum";
import OnGoingVisitsCard from "./OnGoingVisitCard";
import { Icon } from "stories/Icons";
import { Menu, Tab, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { getRouteBySpecialty } from "helpers/functions";
import { useDispatch } from "react-redux";
import { currentSpeciality } from "reducers/currentUserReducer";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { useCurrentUser } from "hooks/useCurrentUser";
import { trackEvent } from "helpers/analytics";
import "./tabs.css";

const { sumBy, filter, startCase, capitalize } = require("lodash");

const VisitTabs = ({ cases, selectedTab, setSelectedTab }) => {
  useEffect(() => {
    const currentStepElement = document
      .getElementById("tabsList")
      ?.querySelector(".activeTab");
    if (currentStepElement) {
      const container = document.getElementById("tabsList");
      const scrollLeft =
        currentStepElement.offsetLeft -
        container.clientWidth / 2 +
        currentStepElement.clientWidth / 2;
      container.scrollLeft = scrollLeft;
    }
  }, [selectedTab]);

  const handleTabChange = (index) => {
    const scrollContainer = document.querySelector(".scroll");
    if (scrollContainer) {
      const scrollPosition = scrollContainer.scrollLeft;
      setSelectedTab(index); // Update the selected tab
      scrollContainer.scrollTo(scrollPosition, 0); // Restore scroll position
    }
  };

  const tabOrder = ["incomplete", "pending", "evaluated", "rejected"];
  return (
    <div className="w-full bg-white divide-y">
      <div className="flex flex-row items-center justify-between px-6 py-4">
        <h3 className="text-3xl font-bold text-black">My Visit</h3>
        <div>
          <ButtonDropDown />
        </div>
      </div>
      <div className="w-full py-6">
        <Tab.Group defaultIndex={selectedTab} onChange={handleTabChange}>
          <Tab.List className="flex space-x-1 border-b scroll" id={"tabsList"}>
            {tabOrder.map((category) => {
              const unreadMessages =
                cases[category]?.length > 0
                  ? sumBy(
                      filter(cases[category], "unread_provider_messages"),
                      "unread_provider_messages"
                    )
                  : 0;
              return (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      "w-56 md:w-80 py-2.5 flex items-center flex-shrink-0 space-x-4 justify-center text-2xl ring-0 focus:ring-0 focus:outline-none",
                      selected
                        ? "border-b-2 border-orange font-bold text-orange activeTab"
                        : "text-blue-100 hover:bg-white/[0.12] font-medium"
                    )
                  }
                >
                  <div> {capitalize(category)}</div>
                  {unreadMessages !== 0 && (
                    <div
                      className={`rounded-full w-6 h-6 text-sm items-center justify-center flex font-bold bg-errorRed text-white`}
                    >
                      {unreadMessages}
                    </div>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {tabOrder.map((status) => (
              <Tab.Panel
                key={status}
                className={classNames(
                  "rounded-xl bg-white p-3",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                )}
              >
                {cases[status] && cases[status].length > 0 ? (
                  <div className="flex flex-col space-y-10">
                    {cases[status].map((caseItem) => (
                      <div
                        key={caseItem.id}
                        className="flex flex-col pt-6 mx-2 space-y-2 border rounded-xl"
                      >
                        <OnGoingVisitsCard
                          id={caseItem.id}
                          title={caseItem.title}
                          speciality={caseItem.speciality}
                          status={status}
                          progress={caseItem.progress ?? []}
                          time={caseItem.updated_at}
                          messageCount={
                            caseItem.unread_provider_messages ??
                            caseItem.unread_messages
                          }
                          messages={caseItem.messages}
                          currentStatus={caseItem.current_status}
                          canMessage={caseItem.can_message}
                          canRefill={caseItem.can_refill}
                          refillRequestSentAt={caseItem?.refill_request_sent_at}
                          receipt={caseItem.receipt}
                          diagnosisSentAt={caseItem.diagnosis_sent_at}
                          receiptGenerating={
                            caseItem.receipt_generating ?? false
                          }
                          practiceUserDetails={{
                            name: caseItem.practice_user?.name,
                            picture: caseItem.practice_user?.picture,
                            speciality: caseItem.practice_user?.speciality,
                            reviewedOn: caseItem.updated_at,
                            evaluationFeedbackGiven:
                              caseItem.evaluation_feedback_rating,
                            evaluationFeedbackToken:
                              caseItem.evaluation_feedback_token,
                            evaluationFeedbackRating:
                              caseItem.evaluation_feedback_rating,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <Placeholder //Temporary placeholder
                    placeholderImage={Images.noVisitsNew}
                    placeholderImageClassName={"h-96"}
                    className={"min-h-modalMinHeight"}
                    heading="No Visits"
                    paragraph={
                      "You haven’t started a visit yet. Go ahead and submit a new visit now"
                    }
                  />
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

function ButtonDropDown() {
  const { userDetails } = useCurrentUser();
  const { hasNoDermaProvider } = useDataFromUserDetails();
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickOption = (option) => {
    const route = getRouteBySpecialty(option, hasNoDermaProvider);
    dispatch(
      currentSpeciality({
        currentSpeciality: speciality[option],
      })
    );
    trackEvent(`my_visit_new_${option}`);
    return history.push(route);
  };

  return (
    <div className="w-full text-right">
      <Menu as="div" className="relative z-10 inline-block text-left">
        <Menu.Button
          id="Add_visit_button"
          as="button"
          className="px-16 py-4 text-2xl font-semibold text-center text-white no-underline rounded-lg w-max bg-orange"
          onClick={() => trackEvent("my_visits_add_visit")}
        >
          <div className="relative">Add Visit</div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="relative">
            <Menu.Items className="absolute right-0 flex flex-col p-4 mt-4 space-y-4 bg-white rounded-md shadow-lg w-max focus:outline-none">
              {[
                userDetails?.is_primary_care_available && "primary_care",
                "dermatology",
                userDetails?.is_pediatric_available && "pediatric",
                userDetails?.is_cosmetic_consultation_available && "cosmetic",
              ].map(
                (option) =>
                  option && (
                    <Menu.Item key={option}>
                      <button
                        onClick={() => onClickOption(option)}
                        className="flex items-center justify-center px-6 py-2 space-x-1 text-2xl font-semibold text-center text-white no-underline bg-white border rounded-lg text-orange hover:bg-pcOrange-bg border-orange"
                      >
                        <div>{`${startCase(option)} Visit`}</div>
                        <Icon name="arrow-right" />
                      </button>
                    </Menu.Item>
                  )
              )}
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    </div>
  );
}

export default VisitTabs;
