import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useCurrentUser } from "hooks/useCurrentUser";
import Spacer from "components/Spacer/Spacer";
import Images from "assets/images";
import { useHistory } from "react-router-dom";
import { useApiMutation } from "hooks/useApiMutation";
import { getVisitTerms } from "api/actions/VisitActions";
import CSSLoader from "components/cssLoader/CSSLoader";
import useToastify from "hooks/useToastify";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";

function CosmeticVisitTerms() {
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [terms, setTerms] = useState([]);
  const { userDetails } = useCurrentUser();
  const { mutate: fetchTerms, loading: fetchingTerms } = useApiMutation(
    getVisitTerms
  );
  const { showAlert } = useToastify();
  const {
    updateConsultation,
    updatingConsultation,
  } = useUpdateCosmeticConsultation();
  const { incompleteData } = useCosmeticConsultationFlow();

  useEffect(() => {
    const init = async () => {
      const result = await fetchTerms("cosmetic");

      setTerms(["Accept All", ...result?.payload]);
    };

    init();
  }, [fetchTerms]);

  useEffect(() => {
    setSelected(
      incompleteData?.accepted_tnc
        ? Array.from({ length: terms?.length }, (_, i) => i)
        : []
    );
  }, [terms, incompleteData]);

  function onSelect(index) {
    if (selected?.includes(index)) {
      if (index === 0) {
        setSelected([]);
      } else {
        setSelected((prev) => prev?.filter((el) => el !== index && el !== 0));
      }
    } else {
      if (index === 0) {
        setSelected(Array.from({ length: terms?.length }, (_, i) => i));
      } else {
        setSelected((prev) => [...prev, index]);
      }
    }
  }

  const acceptTerms = () => {
    if (incompleteData?.accepted_tnc) {
      history.push("/consultation/cosmetic/checkout");
    } else {
      updateConsultation({
        payload: {
          case: {
            accepted_tnc: true,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          showAlert("Terms Accepted", "success");
          history.push("/consultation/cosmetic/checkout");
        },
      });
    }
  };

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header title="SkyMD's Code of Conduct" hideBackBtn={fetchingTerms} />
      <div className="h-eliminateHeaderWithFooter bg-white overflow-y-auto wrapper">
        <Spacer height={30} />
        <MedicalAssistant
          xOffset={23}
          content={
            <p>
              {userDetails?.first_name}, please accept SkyMD’s code of conduct
              before proceeding.
            </p>
          }
        />
        <Spacer height={27} />
        <div className="bg-alternativeWhite pb-16">
          <div className="px-12">
            {fetchingTerms ? (
              <div className="flex-1 py-10 flex justify-center items-center">
                <CSSLoader className="w-16 h-16" color="orange" />
              </div>
            ) : (
              terms?.map((term, index) => {
                return (
                  <div className="py-10" key={index}>
                    <div
                      onClick={() => onSelect(index)}
                      className="flex cursor-pointer"
                    >
                      <img
                        className="w-12 h-12"
                        src={
                          selected?.includes(index)
                            ? Images.checkedOrange
                            : Images.uncheckedCircle
                        }
                        alt="checked"
                      />
                      <p
                        className="pl-5 text-2xl leading-8 font-light pt-1"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(term),
                        }}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <BottomFixedButton
        disabled={
          updatingConsultation || selected?.length >= terms?.length - 1
            ? false
            : true
        }
        loading={updatingConsultation}
        onClick={acceptTerms}
        label="Accept & Continue"
      />
    </div>
  );
}

export default CosmeticVisitTerms;
