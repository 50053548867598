import React, { useState } from "react";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import Button from "components/buttons/Button";
import FormikErrorFocus from "formik-error-focus";
import { useApiMutation } from "hooks/useApiMutation";
import { emailVerify, partialSignup } from "api/actions/AuthActions";

import {
  SignupFirstStep,
  emailValidation,
} from "../../helpers/validationSchema";
import useToastify from "hooks/useToastify";
import Password from "components/inputs/Password";
import useModal from "hooks/useModal";
import Images from "assets/images";
import TextDivider from "components/Divider/TextDivider";
import GoogleLogin from "./GoogleLogin";
import { trackEvent } from "helpers/analytics";
import { useParams } from "react-router-dom";

const CreateAccountForm = ({
  formRef,
  formValues,
  formDispatch,
  loading,
  gettingProviders,
}) => {
  const { showAlert } = useToastify();
  const { slotId } = useParams();

  const {
    mutate: callPartialSignupApi,
    loading: emailLoading,
  } = useApiMutation(partialSignup);
  const { mutate: callEmailVerify, loading: verifyEmail } = useApiMutation(
    emailVerify
  );

  const [isAdult, setIsAdult] = useState(formValues.step === 3);
  const { setShowModal } = useModal();

  async function validateEmail(email) {
    try {
      const result = await callEmailVerify(email);
      if (result.error && (result.payload.error || result.payload.message)) {
        showAlert(result.payload.error || result.payload.message, "error");
      } else {
        return formDispatch({ type: "SHOW_PASSWORD" });
      }
    } catch (err) {
      showAlert(err.message, "error");
    }
  }

  const ReadMoreButton = () => {
    return (
      <button
        type="button"
        className="text-2xl font-medium underline cursor-pointer md:text-xl text-indigo"
        onClick={() =>
          setShowModal({
            state: true,
            children: (_, onClose) => (
              <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
                <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
                  <p className="text-3xl font-bold">Info</p>
                  <p className="text-2xl font-medium leading-9">
                    If you are under 18, please ask your parent to create an
                    account and add your profile to their registered account.
                  </p>
                  <button
                    className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                    onClick={onClose}
                  >
                    Okay
                  </button>
                </div>
              </div>
            ),
            data: null,
          })
        }
      >
        read more
      </button>
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        email: formValues.email,
        password: formValues.password,
      }}
      validationSchema={SignupFirstStep}
      onSubmit={async (values) => {
        try {
          const result = await callPartialSignupApi({
            email: values.email,
          });
          if (
            result.error ||
            result.payload.error ||
            result.payload.error_msg
          ) {
            showAlert(
              result.payload.error || result.payload.error_msg,
              "error"
            );
          } else {
            formDispatch({ type: "FIRST_STEP", payload: values });
          }
        } catch (err) {
          showAlert(err, "error");
        }
      }}
    >
      {({ values, dirty, isValid }) => (
        <Form className="p-4 md:p-0" key="first-step">
          <div className="p-6 bg-white md:space-y-6 md:p-0 rounded-3xl">
            <h1 className="hidden mb-3 text-4xl font-medium text-center text-black md:mb-0">
              Create Account
            </h1>
            <p className="hidden mb-4 text-2xl font-medium text-center text-grey-700 md:text-left">
              Please enter your email{" "}
              {formValues.showAllFields ? "and password to sign up" : ""}
            </p>
            {!formValues.showAllFields ? (
              <div className="grid grid-cols-1 grid-rows-2 gap-y-6 required-label">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  id="email"
                  placeholder="johndoe@xyz.com"
                  customonblur={() =>
                    formDispatch({
                      type: "UPDATE_EMAIL",
                      payload: { email: values.email },
                    })
                  }
                />
                <div className="flex flex-col items-center justify-center gap-y-6 md:gap-y-12">
                  <Button
                    label={"Next"}
                    type="button"
                    loading={
                      loading || gettingProviders || emailLoading || verifyEmail
                    }
                    disabled={!emailValidation.isValidSync(values.email)}
                    onClick={() => validateEmail(values.email)}
                    className="py-4 md:h-18"
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col space-y-6 required-label">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  id="email"
                  placeholder="johndoe@xyz.com"
                />
                <Password
                  name="password"
                  label="Password"
                  id="password"
                  placeholder="Minimum 8 characters"
                  autoComplete="off"
                />
                <div className="flex flex-col items-center justify-center space-y-4 ">
                  <div
                    className="flex items-start space-x-2 cursor-pointer md:self-start"
                    onClick={() => setIsAdult((prev) => !prev)}
                  >
                    <img
                      className="object-contain w-8 h-8"
                      src={
                        isAdult ? Images.squareChecked : Images.squareUnhecked
                      }
                      alt="I am 18 years old or over checkbox"
                    />
                    <p className="text-2xl font-light leading-8 md:text-xl text-indigo">
                      By checking this box, you attest that you’re 18 years of
                      age or older.{" "}
                      <span className="inline text-2xl font-light leading-8 text-left text-indigo md:block md:text-xl">
                        If you are under 18, please <ReadMoreButton />
                      </span>
                    </p>
                  </div>
                  <p className="hidden text-2xl font-light leading-8 text-center text-indigo">
                    If you are under 18, please <ReadMoreButton />
                  </p>
                  <div className="w-full pt-8">
                    <Button
                      id="signup_next"
                      type="submit"
                      label={"Continue"}
                      loading={loading || gettingProviders || emailLoading}
                      disabled={
                        loading ||
                        gettingProviders ||
                        !isAdult ||
                        emailLoading ||
                        !((dirty || formValues.step === 3) && isValid)
                      }
                      className="py-4 md:h-18"
                    />
                  </div>
                </div>
              </div>
            )}
            <TextDivider />
            <a href="/phone" className="no-underline">
              <Button
                outlined={true}
                className={"h-18 my-8"}
                type="button"
                label={"Continue with Phone Number"}
                onClick={() => trackEvent("phone_signup")}
                fontSize="text-2.5xl"
              />
            </a>
            <GoogleLogin source="sign_up" />
            <p className="mt-8 text-2xl font-light leading-8 text-center text-indigo">
              Already have an account?{" "}
              <a
                className="text-2xl font-medium no-underline cursor-pointer text-orange"
                href={slotId ? `/login/${slotId}` : "/login"}
              >
                Login
              </a>
            </p>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateAccountForm;
