import Images from "assets/images";
import classNames from "classnames";
import Spacer from "components/Spacer/Spacer";
import { trackEvent } from "helpers/analytics";
import React from "react";
import { useHistory } from "react-router-dom";
import { snakeCase } from "lodash";

function Header({
  title,
  hideBackBtn,
  customView,
  leftActionView,
  rightActionView,
  titleClassName,
  onBackButtonClick,
  bottomView,
  bgColor = "bg-veryLightBlue",
  className,
  wrapperClassName,
}) {
  const history = useHistory();

  function checkGoBack() {
    let currentUrl = window.location.href;
    history.goBack();
    setTimeout(() => {
      if (currentUrl === window.location.href) {
        history.replace("/home");
      }
    }, 500);
  }

  return (
    <div className={wrapperClassName}>
      <div className={classNames("fixed top-0 z-10 w-full h-28", bgColor)}>
        <div
          className={classNames(
            "flex items-center h-full px-4 justify-between py-0 bg-white border-b border-solid wrapper border-borderColor",
            className
          )}
        >
          {hideBackBtn ? null : leftActionView ? (
            leftActionView
          ) : (
            <img
              onClick={() => {
                onBackButtonClick ? onBackButtonClick() : checkGoBack();
                title && trackEvent(`back_icon_${snakeCase(title)}`);
              }}
              className="object-contain w-10 cursor-pointer "
              src={Images.arrowBack}
              alt="back"
            />
          )}
          {customView ? <div className="my-0">{customView}</div> : null}
          {title ? (
            <h2
              className={`my-0 mx-auto font-bold text-3xl text-indigo ${titleClassName}`}
            >
              {title}
            </h2>
          ) : null}
          {rightActionView && rightActionView}
        </div>
      </div>
      <Spacer height={70} />
      {bottomView}
    </div>
  );
}

export default Header;
