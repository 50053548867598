import React, { useRef, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { userProfileAction } from "api/actions/UserActions";
import Images from "assets/images";
import ImageComponent from "components/imageComponent/ImageComponent";
import Spacer from "components/Spacer/Spacer";
import { useApiQuery } from "hooks/useApiQuery";
import ProfileBottomTab from "./ProfileBottomTab";
import _ from "lodash";
import moment from "moment";
import LoaderComponent from "components/loader/LoaderComponent";
import useModal from "hooks/useModal";
import UpdateProfileModal from "./UpdateProfileModal";
import constants from "helpers/constants";
import Placeholder from "components/placeholders/Placeholder";
import SimpleHeader from "components/header/SimpleHeader";

function Profile() {
  const firstTimeRef = useRef(true);
  const history = useHistory();
  let { url } = useRouteMatch();

  const { setShowModal } = useModal();

  const {
    status: profileDetailsStatusCode,
    payload: profileDetails,
    loading: fetchingProfileDetails,
    query: getProfileDetails,
  } = useApiQuery(userProfileAction, false);

  const ServerMaintainance = useCallback(() => {
    return (
      <div className="h-full bg-white">
        <div className="h-screen">
          <Placeholder
            buttonLoading={fetchingProfileDetails}
            onPressButton={() => window.location.reload()}
            buttonText="Refresh"
            placeholderImageClassName={"w-4/12"}
            placeholderImage={Images.teaCup}
            heading="Something’s not right!"
            paragraph="We are sorry for the inconvinence caused. We are working on the issue
Please try after sometime or check your internet connection."
          />
        </div>
      </div>
    );
  }, [fetchingProfileDetails]);

  useEffect(() => {
    if (
      profileDetailsStatusCode &&
      constants.SERVER_ERROR_CODES?.includes(profileDetailsStatusCode)
    ) {
      setShowModal({
        state: true,
        children: ServerMaintainance,
      });
    }
  }, [profileDetailsStatusCode, setShowModal, ServerMaintainance]);

  useEffect(() => {
    getProfileDetails();
    firstTimeRef.current = false;
  }, [getProfileDetails]);

  if (
    !profileDetails ||
    (fetchingProfileDetails && firstTimeRef?.current === true)
  ) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Fetching profile details",
        }}
      />
    );
  }

  return (
    <div className="flex flex-col overflow-hidden bg-veryLightBlue">
      <SimpleHeader
        title="Profile"
        className="mr-2"
        rightActionView={
          <img
            onClick={() => history.push(`${url}/settings`)}
            src={Images.settingsIcon}
            alt="settings"
            className="w-10 cursor-pointer"
          />
        }
      />
      <div className="flex-1 overflow-y-auto bg-white ">
        <div className="mb-32 md:mb-0">
          <div className="px-12 md:pr-20">
            <Spacer height={30} />
            <div className={"flex items-center"}>
              <ImageComponent url={profileDetails?.basic_info?.photo_url} />
              <div className="w-full ml-6 md:flex md:items-start md:justify-between">
                <h3 className="text-4xl font-bold md:flex-1 md:pr-4">
                  {profileDetails?.basic_info?.first_name +
                    " " +
                    profileDetails?.basic_info?.last_name}
                </h3>
                <button
                  onClick={() => {
                    setShowModal({
                      state: true,
                      children: (_, onClose) => (
                        <UpdateProfileModal
                          basicDetails={profileDetails?.basic_info}
                          onClose={onClose}
                          refetch={getProfileDetails}
                        />
                      ),
                    });
                  }}
                  className="mt-8 border border-orange rounded-xl md:mt-0"
                >
                  <p className="px-10 py-2 text-2xl font-bold text-orange">
                    Update Profile
                  </p>
                </button>
              </div>
            </div>
            <Spacer height={22} />
            <hr className="bg-borderColor" />
            <Spacer height={20} />
            <div>
              <h3 className="text-2xl font-bold opacity-50">Email</h3>
              <p className="pt-2 text-3xl font-medium">
                {profileDetails?.basic_info?.email}
              </p>
            </div>
            <Spacer height={22} />
            <hr className="bg-borderColor" />
            <Spacer height={20} />
            <div>
              <h3 className="text-2xl font-bold opacity-50">Mobile</h3>
              <p className="pt-2 text-3xl font-medium">
                {profileDetails?.basic_info?.pretty_mobile}
              </p>
            </div>
            <Spacer height={22} />
            <hr className="bg-borderColor" />
            <Spacer height={20} />
            <div className="flex">
              <div className="flex-1">
                <h3 className="text-2xl font-bold opacity-50">
                  Gender at Birth
                </h3>
                <p className="pt-2 text-3xl font-medium">
                  {profileDetails?.basic_info?.gender
                    ? _.capitalize(profileDetails?.basic_info?.gender)
                    : "-"}
                </p>
              </div>
              <div className="flex-1">
                <h3 className="text-2xl font-bold opacity-50">DOB</h3>
                <p className="pt-2 text-3xl font-medium">
                  {profileDetails?.basic_info?.dob
                    ? moment(
                        profileDetails?.basic_info?.dob,
                        "YYYY MM DD"
                      ).format("DD MMM, YYYY")
                    : "-"}
                </p>
              </div>
            </div>
            <Spacer height={22} />
            <hr className="bg-borderColor" />
            <Spacer height={20} />
            <div>
              <h3 className="text-2xl font-bold opacity-50">Address</h3>
              {profileDetails?.basic_info?.street_add_1?.length &&
              profileDetails?.basic_info?.street_add_2?.length ? (
                <>
                  <p className="pt-2 text-3xl font-medium">
                    {profileDetails?.basic_info?.street_add_1}
                    {", "}
                  </p>
                  <p className="pt-2 text-3xl font-medium">
                    {profileDetails?.basic_info?.street_add_2}
                    {", "}
                    {profileDetails?.basic_info?.city}
                    {", "}
                    {profileDetails?.basic_info?.zip}
                  </p>
                </>
              ) : profileDetails?.basic_info?.street_add_1?.length ? (
                <p className="pt-2 text-3xl font-medium">
                  {profileDetails?.basic_info?.street_add_1}
                  {", "} {profileDetails?.basic_info?.city}
                  {", "}
                  {profileDetails?.basic_info?.zip}
                </p>
              ) : (
                <p className="pt-2 text-3xl font-medium">
                  {profileDetails?.basic_info?.city}
                  {", "}
                  {profileDetails?.basic_info?.zip}
                </p>
              )}
            </div>
            <Spacer height={22} />
          </div>
          <div className="bg-alternativeWhite md:pr-20">
            <ProfileBottomTab
              loading={fetchingProfileDetails}
              profileDetails={profileDetails}
              refetch={getProfileDetails}
            />
            <div
              onClick={() => history.push(`${url}/health-profile`)}
              className="flex items-start justify-between px-10 py-10 border-t cursor-pointer border-borderColor"
            >
              <h3 className="text-3xl font-medium">Health Profile</h3>
              <div>
                <img className="w-8" src={Images.arrowRight} alt="arrow" />
              </div>
            </div>
            <div
              onClick={() => history.push(`${url}/payments`)}
              className="flex items-start justify-between px-10 py-10 border-t cursor-pointer border-borderColor"
            >
              <h3 className="text-3xl font-medium">Payments</h3>
              <div>
                <img className="w-8" src={Images.arrowRight} alt="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
