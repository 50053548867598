//This is very Incomplete Visit API Specific function
function calculatePercentage(steps) {
  let totalSubsteps = 0;
  let completedSubsteps = 0;

  steps.forEach((step) => {
    if (step.status === "completed") {
      if (step.sub_steps) {
        totalSubsteps += step.sub_steps.length;
        completedSubsteps += step.sub_steps.length;
      }
    } else if (step.status === "current" && step.sub_steps) {
      totalSubsteps += step.sub_steps.length;
      completedSubsteps += step.sub_steps.filter(
        (substep) =>
          substep.status === "completed" || substep.status === "current"
      ).length;
    } else if (step.sub_steps) {
      totalSubsteps += step.sub_steps.length;
    }
  });

  if (totalSubsteps === 0) {
    return 0; // No substeps, completion is 0%
  }

  const completionPercentage = (completedSubsteps / totalSubsteps) * 100;
  return Math.round(completionPercentage);
}
function getRouteBySpecialty(specialtyName, hasNoDermaProvider) {
  switch (specialtyName) {
    case "primary_care":
      return "/visit/primary-care/complete-visit-details";
    case "dermatology":
      return hasNoDermaProvider
        ? "/visit/medical-intake"
        : "/visit/dermatology";
    case "ped":
    case "pediatric":
      return "/visit/pediatrics/complete-visit-details";
    case "cosmetic":
      return "/consultation";
    default:
      return "/";
  }
}
// function getVisitPlaceHolderText(tab) {
//   switch (tab) {
//     case "incomplete":
//       return "You haven’t started a visit yet. Go ahead and submit a new visit now";
//     case "pending":
//       return "You haven’t started a visit yet. Go ahead and submit a new visit now";
//     case "evaluated":
//       return "/visit/pediatrics/complete-visit-details";
//     case "rejected":
//       return "/consultation";
//     default:
//       return "You haven’t started a visit yet. Go ahead and submit a new visit now";
//   }
// }
function getServiceMessage(speciality) {
  switch (speciality) {
    case "expired":
      return "Our doctors' license has expired and they won't be available for consultations until renewed.";
    case "dermatology":
    case "cosmetic":
      return "Unlock glowing skin - complete the process and take charge of your skin's health now";
    case "primary_care":
    case "ped":
    case "pediatric":
      return "Unlock medical care - complete the process and take care and maintaining good health.";
    default:
      return "Choose your Preferred Service - Book your Appointment TODAY.";
  }
}

const genericSteps = (currentStep) => [
  { label: "Visit submitted", isCompleted: currentStep > 1 },
  { label: "Review of medical intake", isCompleted: currentStep > 2 },
  { label: "Doctor reviewing your case", isCompleted: currentStep > 3 },
  {
    label: "Treatment plan ready",
    isCompleted: currentStep > 4,
  },
];

//This function will help if we plan to have iterations in the future to my visits process
//Update : Currently we are keeping same steps for all services, but this might be handy later
const getVisitStatus = (speciality, currentStep) => {
  switch (speciality) {
    case "primary_care":
    case "ped":
    case "pediatric":
    case "dermatology":
    case "cosmetic":
      return genericSteps(currentStep);

    default:
      return genericSteps(currentStep);
  }
};

//Visit Details Data Set
const myVisitDetails = (data) => [
  {
    label: "Visit Details",
    key: "visit_details",
    content: {
      patient_name: data.patient_name,
      state: data?.state,
      patient_photo_id: data?.patient_photo_id,
      selected_date: data?.meta?.booking_info?.selectedDate,
      slot_timing: data?.meta?.booking_info?.slotInfo?.start_time,
      slot_timing_end: data?.meta?.booking_info?.slotInfo?.end_time,
      previous_diagnosis: data?.previous_diagnosis,
      condition: data?.cosmetic_concern,
      visit_condition: data?.skin_issue,
      affected_parts: data?.affected_body_parts,
      condition_status: data?.skin_issue_status,
      condition_days: data?.skin_issue_duration
        ? data?.skin_issue_duration?.split(" ")?.[0] +
          " " +
          data?.skin_issue_duration?.split(" ")?.[1] +
          "(s)"
        : null,
      condition_severity: data?.skin_issue_severity,
    },
  },
  {
    label: "Symptoms",
    key: "symptoms",
    content: {
      raw:
        data.speciality === "pediatric"
          ? data.pediatric_symptoms
          : data.speciality === "primary_care"
          ? data.primary_care_symptoms
          : data.symptoms,
      worse: data.worse,
      better: data.better,
    },
  },
  {
    label: "Medical History",
    key: "medical_history",
    content: {
      medical_history: data.history,
      allergies: data.allergies,
      medications: data.medications,
      skin_care_products: data.skin_care_products,
      additional_info: data.additional_info,
      pharmacy_details: data.pharmacy_details,
    },
  },
  {
    label: "SkyMd's Code of Conduct",
    key: "coc",
    content: data?.code_of_conduct,
  },
];

const evaluatedVisitDetails = (data) => [
  {
    label: "Diagnosis",
    key: "diagnosis",
    content: {
      diagnosis: data.diagnosis.diagnosis,
      diagnosis_details: data?.diagnosis.diagnosis_detail_html,
      other_details: data?.diagnosis?.otherInstructionsHtml,
      follow_up_instruction: data?.diagnosis?.followUpInstruction,
    },
  },
  data?.diagnosis?.medications.length > 0 && {
    label: "Prescription Medicines",
    key: "prescription",
    content: {
      medications: data?.diagnosis?.medications,
      pharmacy_details: data.pharmacy_details,
    },
  },
  data?.diagnosis?.otc_medications.length > 0 && {
    label: "Over-the-Counter",
    key: "otc",
    content: {
      id: data?.id,
      patient_first_name: data?.patient_first_name,
      available_OTC:
        data?.diagnosis?.otc_medications?.some((med) => med?.available) &&
        !data?.diagnosis?.order,
      available: {
        patient_id: data?.patient_id,
        medicines: data?.diagnosis?.otc_medications?.filter(
          (el) => el.available
        ),
        order: data?.diagnosis?.order,
        otc_order: data?.diagnosis?.otc_order,
        otc_med_total_price: data?.diagnosis?.otc_med_total_price,
        shipping_id: data?.diagnosis?.otc_order?.shipping
          ? data?.diagnosis?.order?.shipping_id
          : "Pending",
        subtotal: data?.diagnosis?.otc_med_sub_total_amt,
        tax_state: data?.diagnosis?.tax?.state,
        tax_number: data?.diagnosis?.tax?.number,
        tax_amount: data?.diagnosis?.tax?.amount,
        shipping_fee: data?.diagnosis?.shipping_fee,
        tracking_link: data?.diagnosis?.otc_order?.shipping?.tracking_link,
        receipt_url: data?.diagnosis?.order?.receipt_url,
        ship_to: data?.shipping_address,
      },
      unavailable_meds: data?.diagnosis?.otc_medications?.filter(
        (el) => !el.available
      ),
    },
  },
];

const getVisitDetails = (speciality, data, status = "pending") => {
  const photos = {
    label: "Photos",
    key: "photo",
    content: [
      data?.detail_close_up_photo_1_url && {
        key: "Close up Photo",
        type: "image",
        data:
          data?.thumbnail_close_up_photo_1_url ??
          data?.detail_close_up_photo_1_url,
      },
      data?.detail_far_away_photo_1_url && {
        key: "Far away Photo",
        type: "image",
        data:
          data?.thumbnail_far_away_photo_1_url ??
          data?.detail_far_away_photo_1_url,
      },
      data?.detail_close_up_photo_2_url && {
        key: "Close up Photo 2",
        type: "image",
        data:
          data?.thumbnail_close_up_photo_2_url ??
          data?.detail_close_up_photo_2_url,
      },
      data?.detail_far_away_photo_2_url && {
        key: "Far away Photo 2",
        type: "image",
        data:
          data?.thumbnail_far_away_photo_2_url ??
          data?.detail_far_away_photo_2_url,
      },
      data?.detail_close_up_photo_3_url && {
        key: "Close up Photo 3",
        type: "image",
        data:
          data?.thumbnail_close_up_photo_3_url ??
          data?.detail_close_up_photo_3_url,
      },
      data?.detail_far_away_photo_3_url && {
        key: "Far away Photo 3",
        type: "image",
        data:
          data?.thumbnail_far_away_photo_3_url ??
          data?.detail_far_away_photo_3_url,
      },
    ],
  };
  const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];
  if (data) {
    if (status === "evaluated") {
      return evaluatedVisitDetails(data);
    } else {
      switch (speciality) {
        case "primary_care":
        case "ped":
        case "pediatric":
          return myVisitDetails(data, speciality);
        case "cosmetic":
          return insert(myVisitDetails(data), 2, photos);
        case "dermatology":
          return insert(myVisitDetails(data), 2, photos);
        default:
          return myVisitDetails(data);
      }
    }
  }
  return null;
};

export {
  calculatePercentage,
  getRouteBySpecialty,
  getVisitStatus,
  getVisitDetails,
  getServiceMessage,
};
