import React, { useCallback, useEffect, useState } from "react";
import {
  bookSlotAction,
  getCosmeticCheckoutDetails,
} from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import Header from "components/header/Header";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import ProviderWithPercentage from "components/providerWithPercentage/ProviderWithPercentage";
import LoaderComponent from "components/loader/LoaderComponent";
import CardPayments from "components/payment/CardPayments";
import useVisitStepChange from "hooks/useVisitStepChange";
import CashOption from "components/choosePaymentMethod/CashOption";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import PromoCode from "pages/home/waitingRoom/visitFlow/common/PromoCode";
import CosmeticConsultationSummary from "./CosmeticConsultationSummary";
import { useApiMutation } from "hooks/useApiMutation";
import useUpdateCosmeticConsultation from "hooks/consultation/useUpdateCosmeticConsultation";
import Alert from "components/alerts/Alert";
import useModal from "hooks/useModal";
import moment from "moment";
import { useCurrentUser } from "hooks/useCurrentUser";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { useHistory } from "react-router";
import { setUserDetails } from "reducers/currentUserReducer";
import { CURRENT_SOURCE } from "helpers/currentSource";
import { useDispatch } from "react-redux";
import useToastify from "hooks/useToastify";
import { cosmetic_steps, speciality } from "helpers/enum";
import { discardVisit } from "api/actions/VisitActions";

import { IoAlertCircleOutline } from "react-icons/io5";
import { useParameterizedQuery } from "react-fetching-library";
import { startCase } from "lodash";
import {
  getCaseImagesPresignedPhotoUrlAction,
  imageUploadErrorReport,
  successImageUploadReport,
  uploadPhotoIdToS3Action,
} from "api/actions/ImageUploadActions";
import {
  getUpdatedValues,
  handleImageChange,
} from "../cosmeticFlow/CosmeticUploadPhotos";
import Images from "assets/images";
import Button from "components/buttons/Button";
import CaseImageUploadInfo from "components/imageUploadInfoModal/CaseImageUploadInfo";

function CosmeticCheckout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { loading } = useVisitStepChange();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();

  const [cards, setCards] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoAmount, setPromoAmount] = useState(0);
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [addingCard, setAddingCard] = useState(false);
  const { incompleteData, completedProgress } = useCosmeticConsultationFlow();
  const {
    loading: isGettingCheckoutDetails,
    query: callGetCheckoutDetails,
    payload: checkoutDetails,
  } = useApiQuery(getCosmeticCheckoutDetails(incompleteData?.id), false);
  const { loading: bookingSlot, mutate: callBookSlot } = useApiMutation(
    bookSlotAction
  );
  const { updateConsultation } = useUpdateCosmeticConsultation();
  const { mutate: callDiscardVisit } = useApiMutation(discardVisit);

  useEffect(() => {
    if (incompleteData?.id) {
      callGetCheckoutDetails();
    }
  }, [callGetCheckoutDetails, incompleteData?.id]);

  // INFO: Image Upload Failure UI
  const [disableReuploadImageBtn, setDisabledReuploadImageBtn] = useState(true);
  const [reuploadImages, setReuploadImages] = useState(
    incompleteData?.failed_images_names
      ?.filter((imageName) => imageName !== "identity_confirmation")
      ?.map((imageName) => {
        return { [imageName]: null };
      }) || []
  );
  const [reuploadImageSubmission, setReuploadImageSubmission] = useState(
    incompleteData?.failed_images_names
      ?.filter((imageName) => imageName !== "identity_confirmation")
      ?.map((imageName) => {
        return { [imageName]: false };
      })
  );
  const [reuploadLoading, setLoading] = useState(false);

  useEffect(() => {
    if (
      reuploadImages?.some(
        (reuploadImage) => !reuploadImage[Object.keys(reuploadImage)[0]]
      )
    ) {
      setDisabledReuploadImageBtn(true);
    } else {
      setDisabledReuploadImageBtn(false);
    }

    let newArr = [...reuploadImages];
    let newArrSubmission = [...reuploadImageSubmission];
    newArr.forEach((reuploadImage, index) => {
      if (reuploadImage[Object.keys(reuploadImage)[0]]) {
        newArrSubmission[index][Object.keys(reuploadImage)[0]] = true;
      } else {
        newArrSubmission[index][Object.keys(reuploadImage)[0]] = false;
      }
    });
    setReuploadImageSubmission(newArrSubmission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reuploadImages]);

  const userAgent = window.navigator
    ? window.navigator.userAgent
    : navigator.userAgent;
  const userPlatform = userAgent.includes("iPhone")
    ? "ios-web"
    : userAgent.includes("Android")
    ? "android-web"
    : "web";

  const {
    query: getPresignedUrl,
    // loading: gettingPreSignedUrl,
  } = useParameterizedQuery(getCaseImagesPresignedPhotoUrlAction);

  const {
    mutate: uploadPhotoIdToS3,
    // loading: uploadingPhotoIdToS3,
  } = useApiMutation(uploadPhotoIdToS3Action);

  const { query: reportImageUploadFailure } = useParameterizedQuery(
    imageUploadErrorReport
  );

  const { query: successImageTracker } = useParameterizedQuery(
    successImageUploadReport
  );

  async function getPresignedUrlFunction({
    uri,
    file,
    imageType,
    extension,
    successCallback,
  }) {
    if (uri?.startsWith("http")) {
      successCallback(null);
      return true; // Returning true as the process is considered successful
    } else {
      try {
        setLoading(true);
        const presignedResult = await getPresignedUrl({
          caseId: incompleteData?.id,
          imageType,
          extension: extension?.split("/")?.[1],
        });

        if (presignedResult) {
          try {
            const uploadPhotoIdToS3Result = await uploadPhotoIdToS3({
              endpoint: presignedResult?.payload?.presigned_url,
              payload: file,
            });

            if (uploadPhotoIdToS3Result) {
              if (uploadPhotoIdToS3Result.error) {
                // setImageUploadError(true);
                // error callback
                await reportImageUploadFailure({
                  patientId: incompleteData?.patient_id,
                  payload: {
                    upload_issue: {
                      case_id: incompleteData?.id,
                      image_type: imageType,
                      filename: presignedResult?.payload?.file_key,
                      file_size: file.size,
                      platform: userPlatform,
                      s3_response: uploadPhotoIdToS3Result.error,
                    },
                  },
                });
                showAlert("Image Upload Failed - Please try again", "error");
                setLoading(false);
              } else {
                await successImageTracker({
                  id: incompleteData?.id,
                  payload: {
                    uploaded_image: imageType,
                  },
                });
              }
              // setImageUploadError(false);
              successCallback({
                kind: imageType,
                filename: presignedResult?.payload?.file_key,
                preSignedImageUrl: presignedResult?.payload?.presigned_url?.split(
                  "?"
                )?.[0],
              });
              return true;
            } else {
              return false;
            }
          } catch (error) {
            setLoading(false);
            showAlert("Image Upload Failed - Please try again", "error");
            setReuploadImages(
              incompleteData?.failed_images_names
                ?.filter((imageName) => imageName !== "identity_confirmation")
                ?.map((imageName) => {
                  return { [imageName]: null };
                }) || []
            );
            await reportImageUploadFailure({
              patientId: incompleteData?.patient_id,
              payload: {
                upload_issue: {
                  image_type: imageType,
                  filename: presignedResult?.payload?.file_key,
                  file_size: file.size,
                  platform: userPlatform,
                  s3_response: error,
                },
              },
            });
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  }

  const caseImageReuploadHandleChange = (e, imageName) => {
    handleImageChange(
      e,
      (val) => {
        let newArr = [...reuploadImages];
        newArr.map((item) => {
          if (Object.keys(item).indexOf(imageName) > -1) {
            return (item[imageName] = val);
          }
          return item;
        });
        setReuploadImages(newArr);
      },
      imageName
    );
  };

  const caseImageReuploadPopup = (keyType) => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <CaseImageUploadInfo
          title={
            keyType.includes("close_up") ? "Close Up Photo" : "Far Away Photo"
          }
          image={
            keyType.includes("close_up")
              ? Images.caseCloseUpPhoto
              : Images.caseFarAwayPhoto
          }
          info={
            keyType.includes("close_up")
              ? "Make sure to take a photo from about 6 inches away [Accepted image formats: PNG, JPG, JPEG]."
              : "Make sure to take a photo from about 1 feet away [Accepted image formats: PNG, JPG, JPEG]."
          }
          onClose={onClose}
          handleChange={(e) => caseImageReuploadHandleChange(e, keyType)}
        />
      ),
    });
  };

  function updateVisitFuncion(values, afterUploadPayload) {
    if (values) {
      updateConsultation({
        payload: {
          case: {
            cosmetic_steps: incompleteData?.cosmetic_steps,
          },
          id: incompleteData?.id,
          case_images: afterUploadPayload,
        },
        change_current_screen: true,
        screen_to_complete: cosmetic_steps.upload_photos,
        callBack: (updatedPayload) => {
          dispatch(
            cosmeticConsultationReducers.upload_photos({
              value: getUpdatedValues(values, updatedPayload),
            })
          );
          showAlert("Missing image(s) submitted successfully", "success");
          setLoading(false);
        },
      });
    }
  }
  async function caseImageReuploadHandleSubmit(values) {
    let afterUploadPayload = [];
    function checkThirdSetPhotos() {
      if (values?.close_up_photo_3?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.close_up_photo_3?.uri,
          file: values?.close_up_photo_3?.file,
          imageType: "close_up_photo_3",
          extension: values?.close_up_photo_3?.file?.type,
          successCallback: (cp3) => {
            if (cp3) {
              afterUploadPayload.push(cp3);
            }
            if (values?.far_away_photo_3?.uri?.length) {
              getPresignedUrlFunction({
                uri: values?.far_away_photo_3?.uri,
                file: values?.far_away_photo_3?.file,
                imageType: "far_away_photo_3",
                extension: values?.far_away_photo_3?.file?.type,
                successCallback: (fp3) => {
                  if (fp3) {
                    afterUploadPayload.push(fp3);
                  }
                  updateVisitFuncion(values, afterUploadPayload);
                },
              });
            } else {
              updateVisitFuncion(values, afterUploadPayload);
            }
          },
        });
      } else if (values?.far_away_photo_3?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.far_away_photo_3?.uri,
          file: values?.far_away_photo_3?.file,
          imageType: "far_away_photo_3",
          extension: values?.far_away_photo_3?.file?.type,
          successCallback: (fp3) => {
            if (fp3) {
              afterUploadPayload.push(fp3);
            }
            updateVisitFuncion(values, afterUploadPayload);
          },
        });
      } else {
        updateVisitFuncion(values, afterUploadPayload);
      }
    }

    function checkSecondSetPhotos() {
      if (values?.close_up_photo_2?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.close_up_photo_2?.uri,
          file: values?.close_up_photo_2?.file,
          imageType: "close_up_photo_2",
          extension: values?.close_up_photo_2?.file?.type,
          successCallback: (cp2) => {
            if (cp2) {
              afterUploadPayload.push(cp2);
            }
            if (values?.far_away_photo_2?.uri?.length) {
              getPresignedUrlFunction({
                uri: values?.far_away_photo_2?.uri,
                file: values?.far_away_photo_2?.file,
                imageType: "far_away_photo_2",
                extension: values?.far_away_photo_2?.file?.type,
                successCallback: (fp2) => {
                  if (fp2) {
                    afterUploadPayload.push(fp2);
                  }
                  checkThirdSetPhotos();
                },
              });
            } else {
              checkThirdSetPhotos();
            }
          },
        });
      } else if (values?.far_away_photo_2?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.far_away_photo_2?.uri,
          file: values?.far_away_photo_2?.file,
          imageType: "far_away_photo_2",
          extension: values?.far_away_photo_2?.file?.type,
          successCallback: (fp2) => {
            if (fp2) {
              afterUploadPayload.push(fp2);
            }
            checkThirdSetPhotos();
          },
        });
      } else {
        checkThirdSetPhotos();
      }
    }

    if (values?.close_up_photo_1?.uri?.length) {
      getPresignedUrlFunction({
        uri: values?.close_up_photo_1?.uri,
        file: values?.close_up_photo_1?.file,
        imageType: "close_up_photo_1",
        extension: values?.close_up_photo_1?.file?.type,

        successCallback: (cp1) => {
          if (cp1) {
            afterUploadPayload.push(cp1);
          }
          if (values?.far_away_photo_1?.uri?.length) {
            getPresignedUrlFunction({
              uri: values?.far_away_photo_1?.uri,
              file: values?.far_away_photo_1?.file,
              imageType: "far_away_photo_1",
              extension: values?.far_away_photo_1?.file?.type,
              successCallback: (fp1) => {
                if (fp1) {
                  afterUploadPayload.push(fp1);
                }
                checkSecondSetPhotos();
              },
            });
          } else {
            checkSecondSetPhotos();
          }
        },
      });
    } else if (values?.far_away_photo_1?.uri?.length) {
      getPresignedUrlFunction({
        uri: values?.far_away_photo_1?.uri,
        file: values?.far_away_photo_1?.file,
        imageType: "far_away_photo_1",
        extension: values?.far_away_photo_1?.file?.type,
        successCallback: (fp1) => {
          if (fp1) {
            afterUploadPayload.push(fp1);
          }
          checkSecondSetPhotos();
        },
      });
    } else {
      checkSecondSetPhotos();
    }
  }

  const getFinalPrice = useCallback(() => {
    if (promoAmount) {
      return Number(checkoutDetails?.amount) - Number(promoAmount);
    } else {
      return Number(checkoutDetails?.amount);
    }
  }, [checkoutDetails, promoAmount]);

  async function updateVisitApi() {
    try {
      const payload = {
        date: incompleteData?.meta?.booking_info?.selectedDate,
        slot_id: incompleteData?.meta?.booking_info?.selectedProvider?.slot_id,
      };
      const result = await callBookSlot({
        caseId: incompleteData?.id,
        payload,
      });

      if (result && result?.error === false) {
        updateConsultation({
          payload: {
            case: {
              coupon_code: isPromoCodeApplied ? promoCode : null,
              status: "complete_awaiting_diagnosis",
              submitting_source: CURRENT_SOURCE,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(cosmeticConsultationReducers.reset());
            dispatch(
              setUserDetails({
                userDetails: {
                  ...userDetails,
                  incomplete_cosmetic_consultation_id: null,
                },
              })
            );
            history.replace({
              pathname: "/consultation/cosmetic/submitted",
              state: {
                bookingInfo: incompleteData?.meta?.booking_info,
              },
            });
          },
        });
      } else {
        if (result.error || result.payload.error || result.payload.error_msg) {
          showAlert(
            result.payload.error ||
              result.payload.error_msg ||
              result.payload.message ||
              "Booking failed.",
            "error"
          );
        }
      }
    } catch (error) {}
  }

  const deleteVisit = () => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  };

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(cosmeticConsultationReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        window.location.replace("/home");
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, incompleteData?.id, userDetails]);

  function completeCheckout() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          onPrimaryButtonClick={() => {
            onClose();
            updateVisitApi();
          }}
          onSecondaryButtonClick={onClose}
          primaryButtonText="Confirm"
          heading="Video Appointment Confirmation"
          message={`By pressing 'Confirm', you are booking your appointment on ${moment(
            incompleteData?.meta?.booking_info?.selectedDate,
            "YYYY-MM-DD"
          ).format("MMMM DD, YYYY")} at ${moment(
            incompleteData?.meta?.booking_info?.slotInfo?.start_time,
            "hh:mm"
          ).format("LT")}.`}
        />
      ),
    });
  }

  function isButtonValid() {
    if (!incompleteData?.images_uploaded) {
      return false;
    }
    if (checkingCoupon) {
      return false;
    } else {
      if (
        typeof getFinalPrice() === "number" &&
        getFinalPrice() === 0 &&
        cards?.length
      ) {
        return true;
      } else if (
        typeof getFinalPrice() === "number" &&
        getFinalPrice() > 0 &&
        cards?.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (isGettingCheckoutDetails || loading || bookingSlot) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: bookingSlot
            ? "Booking your slot."
            : loading
            ? "Submitting your consultation. Do not press the refresh (or) go back button"
            : "Please wait",
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="Appointment Details"
        hideBackBtn={loading}
        rightActionView={
          <button
            className="text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer"
            onClick={deleteVisit}
          >
            Delete
          </button>
        }
      />
      <div className="h-eliminateHeaderWithFooter bg-white overflow-y-auto wrapper">
        <div className="py-8">
          <MedicalAssistant
            xOffset={23}
            content={
              <p>
                Yay! Your consultation is almost complete. Please select mode of
                payment and submit the consultation.
              </p>
            }
          />
        </div>
        <ProviderWithPercentage
          doctorDetails={{
            provider_id:
              incompleteData?.meta?.booking_info?.selectedProvider?.id,
            provider_photo_url:
              incompleteData?.meta?.booking_info?.selectedProvider?.avatar,
            provider_name:
              incompleteData?.meta?.booking_info?.selectedProvider?.name,
            speciality: speciality.cosmetic,
          }}
          percentage={completedProgress}
        />
        {incompleteData?.failed_images_names?.length > 0 && (
          <div className="space-y-4 mx-12 my-6 p-4 rounded-md bg-yellow-200">
            <p className="font-bold text-2xl flex items-center gap-3">
              <IoAlertCircleOutline /> OOPS! Your photos didn't upload. Select
              link(s) below to re-upload images again to submit your visit.
            </p>
            <div>
              <div className="flex flex-col">
                {incompleteData?.failed_images_names
                  ?.filter((el) => el !== "identity_confirmation")
                  ?.map((e, index) => (
                    <div
                      key={index}
                      className="py-2 flex justify-between items-center cursor-pointer hover:underline"
                      onClick={() => caseImageReuploadPopup(e)}
                    >
                      <p className="text-xl font-semibold">
                        {index + 1}. {startCase(e)}
                      </p>
                      <p class="font-semibold">
                        {reuploadImageSubmission.reduce(
                          (acc, curr) => Object.assign(acc, curr),
                          {}
                        )[e]
                          ? "Completed"
                          : "Click here"}
                      </p>
                    </div>
                  ))}
              </div>
              {incompleteData?.failed_images_names?.filter(
                (el) => el !== "identity_confirmation"
              )?.length > 0 && (
                <Button
                  textSize="text-2xl"
                  className="px-4 py-2"
                  disabled={disableReuploadImageBtn}
                  loading={reuploadLoading}
                  onClick={() =>
                    caseImageReuploadHandleSubmit(
                      reuploadImages.reduce(
                        (acc, curr) => Object.assign(acc, curr),
                        {}
                      )
                    )
                  }
                  label="Submit Photos"
                />
              )}
            </div>
          </div>
        )}
        <div className="px-12 pt-8">
          <CashOption
            hideBreakDown
            isActive={true}
            onClick={() => {}}
            price={checkoutDetails?.amount}
          />
        </div>
        <div className="px-12 pt-8">
          <CardPayments
            amount={Math.max(getFinalPrice()).toFixed(2)}
            setAddingCard={setAddingCard}
            cards={cards}
            setCards={setCards}
            caseId={incompleteData?.id}
          />
        </div>
        {incompleteData?.allow_promo_code &&
        Number(checkoutDetails?.amount) > 0 ? (
          <div className="px-12 py-8">
            <PromoCode
              promoAmount={promoAmount}
              amount={checkoutDetails?.amount}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              setPromoAmount={setPromoAmount}
              isPromoCodeApplied={isPromoCodeApplied}
              setIsPromoCodeApplied={setIsPromoCodeApplied}
              setCheckingCoupon={setCheckingCoupon}
              speciality={speciality.cosmetic}
            />
          </div>
        ) : null}
        <CosmeticConsultationSummary data={incompleteData} />
      </div>
      <BottomFixedButton
        onClick={completeCheckout}
        loading={loading || addingCard}
        label={addingCard ? "Adding card" : "Schedule My Appointment"}
        disabled={isButtonValid() ? false : true}
      />
    </div>
  );
}

export default CosmeticCheckout;
