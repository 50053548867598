import React, { useCallback, useEffect } from "react";
import VisitStepContainer from "components/visitStepContainer/VisitStepContainer";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import { getPrimaryCareStepScreen } from "../primaryCare/getPrimaryCareStepScreen";
import { useDispatch } from "react-redux";
// import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import Alert from "components/alerts/Alert";
import useModal from "hooks/useModal";
import { useApiMutation } from "hooks/useApiMutation";
import { discardVisit } from "api/actions/VisitActions";
import { setUserDetails } from "reducers/currentUserReducer";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useHistory } from "react-router-dom";
import usePatients from "hooks/visit/usePatients";
import useIncompleteData from "hooks/visit/useIncompleteData";
import { speciality } from "helpers/enum";

function PrimaryCareSubStepScreen() {
  const dispatch = useDispatch();
  const { currentScreen, subSteps, incompleteData } = usePrimaryCareVisitFlow();
  // const { pcDoctorDetails } = useDataFromUserDetails();

  const { setShowModal } = useModal();
  const { mutate: callDiscardVisit } = useApiMutation(discardVisit);
  const { userDetails } = useCurrentUser();
  const history = useHistory();

  const { getPatients } = usePatients();

  const { fetchIncompleteData } = useIncompleteData();

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(primaryCareVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.goBack();
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, incompleteData?.id, userDetails, history]);

  const deleteVisit = () => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  };

  useEffect(() => {
    getPatients();
    fetchIncompleteData(speciality.primary_care);
  }, [getPatients, fetchIncompleteData]);

  return (
    <VisitStepContainer
      speciality="primary_care"
      incompleteData={incompleteData}
      doctorDetails={{
        provider_name: incompleteData?.provider_name,
        provider_id: null,
        provider_photo_url: incompleteData?.provider_photo_url,
        speciality: 2,
      }}
      currentScreen={currentScreen}
      subSteps={subSteps}
      onClickBack={(backAnimation) => {
        backAnimation();
        setTimeout(() => {
          dispatch(
            primaryCareVisitFlowReducers.currentScreen({
              value: subSteps?.[currentScreen]?.prevScreen,
            })
          );
        }, 300);
      }}
      rightActionView={
        <button
          className={`text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer${
            incompleteData?.id ? "" : " hidden"
          }`}
          onClick={deleteVisit}
        >
          Delete
        </button>
      }
    >
      {getPrimaryCareStepScreen(currentScreen)}
    </VisitStepContainer>
  );
}

export default PrimaryCareSubStepScreen;
