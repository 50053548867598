import React from "react";
import classNames from "classnames";

const Step = ({ number, active, completed, allowPrev = true }) => {
  return (
    <div
      className={classNames(
        "flex justify-center items-center w-12 h-12 border",
        completed && "bg-orange border-orange text-white",
        completed && allowPrev && "cursor-pointer",
        active && "border-orange bg-transparent text-orange",
        !active && !completed && "bg-grey border-grey-600 text-grey-600",
        "rounded-full bg-transparent"
      )}
    >
      <p className="text-2xl font-bold">{number}</p>
    </div>
  );
};

const StepLabel = ({ text, active, className, completed }) => {
  return (
    <p
      className={classNames(
        "font-bold text-2xl",
        active || completed ? "text-orange" : "text-grey-500",
        className
      )}
    >
      {text}
    </p>
  );
};

const StepContainer = ({
  second,
  prev,
  allowPrev = true,
  firstLabel = "Create Account",
  secondLabel = "Complete Profile",
}) => {
  return (
    <div className="flex flex-col text-white px-24 py-4 m-0 md:rounded-3xl bg-alternativeWhite md:bg-transparent md:px-16 md:mt-8">
      <div className="flex items-center justify-between w-full ">
        <div
          className="flex items-center space-x-4"
          onClick={second && allowPrev ? prev : () => void 0}
        >
          <Step
            number={1}
            text={firstLabel}
            active={!second}
            completed={second}
            allowPrev={allowPrev}
          />
          <StepLabel
            active={!second}
            text={firstLabel}
            className={"hidden"}
          />
        </div>
        <div
          className={classNames(
            "flex-grow border-t block ",
            second ? "border-orange" : "border-grey-600"
          )}
        ></div>

        <div className="flex items-center space-x-4">
          <Step number={2} text={firstLabel} active={second} />
          <StepLabel
            active={second}
            text={secondLabel}
            className={"hidden"}
          />
        </div>
      </div>
      <div className="justify-between -mx-16 flex pt-1.5">
        <StepLabel active={!second} text={firstLabel} completed={second} />
        <StepLabel active={second} text={secondLabel} />
      </div>
    </div>
  );
};

export default StepContainer;
