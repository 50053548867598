import React, { useEffect, useRef } from "react";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { Form, Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";
import FullBorderSelect from "components/inputs/FullBorderSelect";
import { useDispatch } from "react-redux";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import {
  dermatology_main_steps,
  dermatology_sub_steps,
  step_status,
} from "helpers/enum";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { conditionDetailsSchema } from "helpers/validationSchema";
import _ from "lodash";
import { checkAlreadyCompleted } from "helpers/setDermatologyStepStatus";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function ConditionDetails() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();

  const { incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.condition_details].value;

  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (
      currentScreenValueFromStore &&
      Object.keys(currentScreenValueFromStore)?.length
    ) {
      if (formRef?.current) {
        formRef?.current?.setValues({
          status: currentScreenValueFromStore?.skin_issue_status
            ? _.capitalize(currentScreenValueFromStore?.skin_issue_status)
            : "",
          count: currentScreenValueFromStore?.skin_issue_duration?.split(
            " "
          )?.[0],
          day: currentScreenValueFromStore?.skin_issue_duration?.length
            ? _.capitalize(
                currentScreenValueFromStore?.skin_issue_duration?.split(
                  " "
                )?.[1]
              ) + "(s)"
            : "",
          severity: currentScreenValueFromStore?.skin_issue_severity
            ? _.capitalize(currentScreenValueFromStore?.skin_issue_severity)
            : "",
        });
      }
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    dispatch(
      dermaVisitFlowReducers.condition_details({
        isValid: true,
      })
    );
  }, [dispatch]);

  function onRightButtonClick(_, nextAnimation) {
    if (formRef?.current) {
      formRef?.current?.handleSubmit();
      nextAnimationRef.current = nextAnimation;
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          skin_issue_status: values?.status?.toLowerCase(),
          skin_issue_duration: `${values?.count} ${values?.day?.replace(
            "(s)",
            ""
          )}`,
          skin_issue_severity: values?.severity?.toLowerCase(),
          dermatology_steps: {
            main_steps: {
              ...incompleteData?.dermatology_steps?.main_steps,
              [dermatology_main_steps.visit_details]: step_status.completed,
              [dermatology_main_steps.photos]: checkAlreadyCompleted({
                screen_name: dermatology_main_steps.photos,
                dermatology_steps: incompleteData?.dermatology_steps,
                step_placement: "main_steps",
              }),
            },
            sub_steps: {
              ...incompleteData?.dermatology_steps?.sub_steps,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.condition_details,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.condition_details({
            value: {
              skin_issue_status: values?.status?.toLowerCase(),
              skin_issue_duration: `${values?.count} ${values?.day?.replace(
                "(s)",
                ""
              )}`,
              skin_issue_severity: values?.severity?.toLowerCase(),
            },
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={<p>Thank you. Now, tell me more about your condition.</p>}
      />
      <Card>
        <Formik
          innerRef={formRef}
          initialValues={{
            status: "",
            count: "",
            day: "",
            severity: "",
          }}
          validationSchema={conditionDetailsSchema}
          onSubmit={onFormSubmit}
        >
          {() => (
            <Form>
              <FullBorderSelect
                default={"Select status"}
                options={["Improving", "Worsening", "Unchanged"]}
                label="Status"
                name="status"
                id="status"
              />
              <Spacer height={21} />
              <label className="text-2xl font-bold text-opacity-50 text-indigo">
                Since when are you suffering from the condition
              </label>
              <Spacer height={8} />
              <div className="flex justify-between">
                <FullBorderSelect
                  default="Select count"
                  options={Array.from({ length: 100 }, (_, i) => i + 1)}
                  name="count"
                  id="count"
                />
                <Spacer width={12} />
                <FullBorderSelect
                  containerClassName="w-full md:flex-1"
                  default="Select day(s)"
                  options={["Day(s)", "Month(s)", "Year(s)"]}
                  name="day"
                  id="day"
                />
              </div>
              <Spacer height={21} />
              <FullBorderSelect
                default="Select severity"
                options={["Mild", "Moderate", "Severe"]}
                label="Severity"
                name="severity"
                id="severity"
              />
              <FormikErrorFocus
                align={"bottom"}
                ease={"linear"}
                duration={500}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </StepScreenContainer>
  );
}

export default ConditionDetails;
