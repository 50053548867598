import Images from "assets/images";
import { speciality } from "helpers/enum";
import { startCase } from "lodash";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
//Old one needs to be depreciated
function NewProviderWithPercentage({
  doctorDetails,
  percentage,
  className = "",
  visitSpeciality,
  id,
}) {
  return (
    <div className={`px-12 py-4 bg-white ${className}`}>
      <div className="flex items-center justify-between wrapper">
        <div className="flex items-center space-x-6">
          <img
            className={"w-32 h-32 rounded-full object-center"}
            src={doctorDetails?.provider_photo_url ?? Images.UserPlaceholder}
            alt="provider_image"
          />
          {doctorDetails?.provider_id ? (
            <div className="flex flex-col space-y-3">
              <p className={"font-medium text-2.5xl text-themeBlack"}>
                Your{" "}
                {doctorDetails?.speciality &&
                doctorDetails?.speciality === speciality.cosmetic
                  ? "consultation"
                  : "visit"}{" "}
                with
              </p>
              <h3 className={"text-3xl text-black font-medium pt-2"}>
                {doctorDetails?.provider_name}
              </h3>
            </div>
          ) : (
            <p className={"font-medium text-2.5xl text-themeBlack"}>
              {startCase(visitSpeciality)} #{id} tracker
            </p>
          )}
        </div>
        <div className="self-center w-24 h-24">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            className={"font-bold "}
            strokeWidth={6}
            styles={buildStyles({
              rotation: 0,
              textSize: "24px",
              pathTransitionDuration: 2,
              pathColor: "#FF8648",
              textColor: "#FF8648",
              trailColor: "#ECECEC",
              backgroundColor: "#3e98c7",
            })}
          />
        </div>
      </div>
    </div>
  );
}

export default NewProviderWithPercentage;
