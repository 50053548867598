import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import constants from "helpers/constants";
import React from "react";

function DownloadAppBanner({
  onClose,
  heading,
  subHeading,
  imageSrc,
  showQR = false,
}) {
  return (
    <div className="h-screen bg-transparent">
      <div
        className={`h-full wrapper bg-transparent flex items-center justify-center md:py-8 md:px-10`}
      >
        <div className="flex flex-col items-center justify-center px-10 bg-white h-full relative md:flex-row md:py-48 md:max-h-modalMaxHeight md:rounded-2xl">
          <div className="flex flex-1 h-1/2 md:h-full items-center justify-center relative">
            <div className="bg-gradient-to-b from-lightBlue to-blue rounded-full w-72 h-72 md:w-96 md:h-96"></div>
            <img
              className="object-contain block w-52 md:w-72 absolute"
              src={imageSrc ?? Images.appScreenshot}
              alt="app_screenshot"
            />
          </div>
          <div className="flex-1 h-1/2 mt-8 md:mt-0 md:h-full flex flex-col md:justify-center ">
            <h1 className="text-3xl md:text-4xl font-bold text-center md:text-left">
              {heading ?? "Download our app and get treated on the go."}
            </h1>
            {subHeading ? (
              <p className="font-thin text-xl mt-4 leading-8 text-center md:text-xl md:mt-4 md:leading-9 md:text-left">
                {subHeading}
              </p>
            ) : null}
            {showQR ? (
              <div className="hidden md:block md:mx-auto md:mt-12">
                <img
                  className="w-48 h-48"
                  src="https://d1xtxvtxcc0yfp.cloudfront.net/eyJidWNrZXQiOiJza3ltZC1wcm9kdWN0aW9uIiwia2V5IjoiU2t5bWQgbWlzY2VsbGFuZW91cy9hcHAtcXItbGl2ZS5qcGVnIn0="
                  alt="QR code for SkyMD app"
                />
              </div>
            ) : (
              <div />
            )}
            <div className="flex items-center mt-12 justify-center md:mt-12 md:justify-start">
              <a
                href={constants.ANDROID_APP_URL}
                target="_blank"
                rel="noreferrer"
                className="w-60 h-16 relative md:w-60 md:h-20"
              >
                <img
                  className="cursor-pointer absolute w-full h-full object-contain"
                  src="https://skymd-assets.s3.amazonaws.com/images/new_images/google-play.png"
                  alt="google_play"
                />
              </a>
              <Spacer width={10} />
              <a
                href={constants.IOS_APP_URL}
                target="_blank"
                rel="noreferrer"
                className="w-60 h-16 relative md:w-60 md:h-20"
              >
                <img
                  className="cursor-pointer absolute w-full h-full object-contain"
                  src="https://skymd-web-images.s3.amazonaws.com/app-store.webp"
                  alt="app_store"
                />
              </a>
            </div>
          </div>
          <img
            alt="close"
            onClick={onClose}
            src={Images.closeIcon}
            className="absolute w-10 right-8 top-8 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}

export default DownloadAppBanner;
