import PharmacyMapView from "components/mapView/PharmacyMapView";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import PharmacyCard from "components/pharmacyCard/PharmacyCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { ped_sub_steps } from "helpers/enum";
import useModal from "hooks/useModal";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdatePedVisit from "hooks/visit/useUpdatePedVisit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";

function SelectPharmacyForPed() {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);

  const { incompleteData, subSteps } = usePedVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[ped_sub_steps.select_pharmacy].value;

  const { updateVisit, updatingVisit } = useUpdatePedVisit();

  useEffect(() => {
    if (
      currentScreenValueFromStore &&
      Object.keys(currentScreenValueFromStore)?.length
    ) {
      setSelectedPharmacy(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    if (selectedPharmacy) {
      dispatch(
        pedVisitFlowReducers.select_pharmacy({
          isValid: true,
        })
      );
    } else {
      dispatch(
        pedVisitFlowReducers.select_pharmacy({
          isValid: false,
        })
      );
    }
  }, [dispatch, selectedPharmacy]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          pharmacy_details: {
            pharmacy_id: selectedPharmacy?.pharmacy_id,
            address: selectedPharmacy?.address,
          },
          pediatric_steps: incompleteData?.pediatric_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: ped_sub_steps.select_pharmacy,
      callBack: () => {
        dispatch(
          pedVisitFlowReducers.select_pharmacy({
            value: {
              pharmacy_id: selectedPharmacy?.pharmacy_id,
              address: selectedPharmacy?.address,
            },
          })
        );
        nextAnimation();
      },
    });
  }
  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            What's your preferred pharmacy for getting prescription medicines to
            you?
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        <PharmacyCard
          pharmacyAddess={selectedPharmacy?.address}
          onClickEdit={() => {
            if (updatingVisit) {
              return null;
            } else {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <PharmacyMapView
                    onClose={onClose}
                    onSelectPharmacy={(pharmacy) =>
                      setSelectedPharmacy(pharmacy)
                    }
                  />
                ),
                data: null,
              });
            }
          }}
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectPharmacyForPed;
