import React, { useEffect } from "react";
import { Form, Formik } from "formik";

import AudioRecorder from "components/audioRecorder/AudioRecorder";
import MultiLineInput from "components/inputs/MultiLineInput";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useDispatch } from "react-redux";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useRef } from "react";
import { useApiMutation } from "hooks/useApiMutation";
import { uploadPhotoIdToS3Action } from "api/actions/ImageUploadActions";
import {usePedVisitFlow} from "hooks/useVisitFlow";
import useToastify from "hooks/useToastify";
import {ped_main_steps, ped_sub_steps} from "helpers/enum";
import useUpdatePedVisit from "hooks/visit/useUpdatePedVisit";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useHistory } from "react-router-dom";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { AdditionalDetailsSchema } from "helpers/validationSchema";
import { getAudioRecordUrlAction } from "api/actions/VisitActions";
import { useCurrentUser } from "hooks/useCurrentUser";

function AdditionalInfo() {
  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();

  const { incompleteData, subSteps } = usePedVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;

  const { userDetails } = useCurrentUser();

  const currentScreenValueFromStore =
    subSteps[ped_sub_steps.additional_info].value;

  const {
    mutate: getPresignedUrl,
    loading: gettingPreSignedUrl,
  } = useApiMutation(getAudioRecordUrlAction);
  const { mutate: uploadAudioToS3, loading: uploadingAudio } = useApiMutation(
    uploadPhotoIdToS3Action
  );
  const { updateVisit } = useUpdatePedVisit();

  useEffect(() => {
    if (currentScreenValueFromStore) {
      formRef?.current?.setValues({
        record_url:
          currentScreenValueFromStore?.additional_info_recording ?? "",
        additional_details: currentScreenValueFromStore?.additional_info ?? "",
      });
    }
  }, [currentScreenValueFromStore]);

  useEffect(() => {
    dispatch(
      pedVisitFlowReducers.additional_info({
        isValid: true,
      })
    );
  }, [dispatch]);

  function onRightButtonClick() {
    if (formRef?.current) {
      formRef?.current?.handleSubmit();
    }
  }

  function updateVisitApi(additional_info, recorded_url) {
    updateVisit({
      payload: {
        case: {
          additional_info: additional_info,
          additional_info_recording: recorded_url ?? null,
          pediatric_steps: {
            main_steps: {
              ...incompleteData?.pediatric_steps?.main_steps,
              [ped_main_steps.additional_details]: "completed",
            },
            sub_steps: {
              ...incompleteData?.pediatric_steps?.sub_steps,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: ped_sub_steps.additional_info,
      callBack: () => {
        dispatch(
          pedVisitFlowReducers.additional_info({
            value: {
              additional_info: additional_info,
              additional_info_recording: recorded_url ?? null,
            },
          })
        );
        history?.push("/visit/pediatrics/checkout");
      },
    });
  }

  async function uploadAudio(additional_details, blob_url) {
    try {
      let blob = await fetch(blob_url).then((r) => r.blob());
      const presignedResult = await getPresignedUrl({
        caseId: incompleteData?.id,
      });
      if (presignedResult) {
        const uploadToS3Result = await uploadAudioToS3({
          endpoint: presignedResult?.payload?.presigned_url,
          payload: blob,
          headers: {
            "Content-Type": "audio/mp4",
          },
        });
        if (uploadToS3Result && uploadToS3Result?.error === false) {
          showAlert("Audio uploaded successfully.", "success");
          updateVisitApi(
            additional_details,
            presignedResult?.payload?.presigned_url?.split("?")[0]
          );
        } else {
          showAlert("Upload failed!", "error");
        }
      }
    } catch (error) {
      showAlert("Something went wrong! Please try again.", "error");
    }
  }

  async function onFormSubmit(values) {
    if (values?.record_url?.length && !values?.record_url?.startsWith("http")) {
      uploadAudio(values?.additional_details, values?.record_url);
    } else {
      updateVisitApi(values?.additional_details, values?.record_url);
    }
  }
  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <>
            <p>{userDetails?.first_name}, if you'd like for me to know anything else about you or your medical condition, please do so below. This is optional.</p>
            <p>
              You can record a voice message for me, or type the information by hand.
            </p>
          </>
        }
      />
      <Spacer height={40} />
      <Card>
        <Formik
          innerRef={formRef}
          onSubmit={onFormSubmit}
          initialValues={{
            record_url: "",
            additional_details: "",
          }}
          validationSchema={AdditionalDetailsSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <label className="font-bold text-2xl text-indigo text-opacity-50">
                Record Audio (optional)
              </label>
              <Spacer height={20} />
              <AudioRecorder
                onClickDelete={() => setFieldValue("record_url", "")}
                audioResult={values?.record_url}
                setAudioResult={(url) => setFieldValue("record_url", url)}
              />
              <Spacer height={20} />
              <MultiLineInput
                label={"Write Details (optional)"}
                id={"additional_details"}
                name={"additional_details"}
                lablel="Type something.."
              />
            </Form>
          )}
        </Formik>
        {gettingPreSignedUrl || uploadingAudio ? (
          <div className="absolute w-full h-full top-0 left-0 bg-alternativeWhite bg-opacity-80 flex items-center justify-center">
            <CSSLoader className="w-12 h-12" color="orange" />
            <p className="text-3xl font-bold">
              Please wait while we upload your audio recording.
            </p>
          </div>
        ) : null}
      </Card>
    </StepScreenContainer>
  );
}

export default AdditionalInfo;
