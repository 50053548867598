import React, { useRef } from "react";
import Header from "components/header/Header";
import Input from "components/inputs/Input";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import moment from "moment";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import { useHistory } from "react-router-dom";
import { useApiMutation } from "hooks/useApiMutation";
import { updateBasicInfoAction } from "api/actions/UserActions";
import { useCurrentUser } from "hooks/useCurrentUser";
import { setUserDetails } from "reducers/currentUserReducer";
import { useDispatch } from "react-redux";
import useToastify from "hooks/useToastify";
import _ from "lodash";
import { CosmeticBasicDetailsSchema } from "helpers/validationSchema";
import { input_types } from "helpers/enum";

function CosmeticBasicDetails() {
  const { userDetails } = useCurrentUser();
  const history = useHistory();

  const formRef = useRef();

  const dispatch = useDispatch();
  const { showAlert } = useToastify();

  const { mutate: updateBasicIfo, loading: isUpdating } = useApiMutation(
    updateBasicInfoAction
  );

  async function onFormSubmit(values) {
    try {
      let payload = {
        id: userDetails?.id,
        user: {
          patients_attributes: [
            {
              id: userDetails?.self_patient_id,
              dob: moment(values?.dob, "YYYY-MM-DD")?.format("YYYY/MM/DD"),
              gender: values?.gender?.toLowerCase(),
              city: values?.city.split(',')[0],
            },
          ],
        },
      };
      const result = await updateBasicIfo({
        userId: userDetails?.id,
        payload,
      });
      if (result?.error === false) {
        if (result?.payload) {
          dispatch(
            setUserDetails({
              userDetails: {
                ...userDetails,
                city: result?.payload?.basic_info?.city,
                dob: result?.payload?.basic_info?.dob,
                gender: result?.payload?.basic_info?.gender,
              },
            })
          );

          history.push("/consultation/cosmetic/pricing");
        }
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong", "error");
    }
  }

  const initialCity = userDetails?.city
    ? userDetails?.state
      ? `${userDetails?.city}, ${userDetails?.state}`
      : userDetails?.city
    : "";

  return (
    <div className="bg-veryLightBlue">
      <Header title="Basic Details" />
      <div className="wrapper overflow-y-scroll h-eliminateHeader bg-blue">
        <div className="px-10 py-8 pb-64 min-h-full">
          <MedicalAssistant
            content={
              <p>
                Please provide the below information for your medical chart with
                our practice.
              </p>
            }
          />
          <Formik
            innerRef={formRef}
            validationSchema={CosmeticBasicDetailsSchema}
            initialValues={{
              city: initialCity,
              dob: userDetails?.dob ?? "",
              gender: userDetails?.gender
                ? _.capitalize(userDetails?.gender)
                : "",
            }}
            onSubmit={onFormSubmit}
          >
            {({ setFieldValue, values, errors, touched }) => (
              <Form className="bg-white rounded-3xl p-12 mt-6">
                <Input name="city" label="City" id="city" placeholder="" />
                <Spacer height={32} />
                <Input
                  name="dob"
                  label="Date of Birth"
                  id="dob"
                  type={input_types.DATEPICKER}
                  max={moment().format("YYYY-MM-DD")}
                  placeholder=""
                />
                <Spacer height={32} />
                <RegularRadioSelect
                  options={["Male", "Female"]}
                  selected={values?.gender}
                  setSelected={(el) => setFieldValue("gender", el)}
                />
                {touched?.gender && errors?.gender ? (
                  <p className="font-bold text-base mt-2 text-red">
                    {errors?.gender}
                  </p>
                ) : null}
                <FormikErrorFocus
                  align={"bottom"}
                  ease={"linear"}
                  duration={500}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <BottomFixedButton
        type="submit"
        label="Submit"
        onClick={() => formRef?.current?.handleSubmit()}
        loading={isUpdating}
      />
    </div>
  );
}

export default CosmeticBasicDetails;
