import React, { useEffect, useCallback } from "react";

import Images from "assets/images";
import { useApiQuery } from "hooks/useApiQuery";
import {
  currentUserAction,
  getIncompleteVisits,
  getSubmittedVisits,
} from "api/actions/UserActions";
import { useDispatch } from "react-redux";
import {
  currentSpeciality,
  setUserDetails,
  updateInsuranceAvailability,
  updateVisitMessageCount,
} from "reducers/currentUserReducer";
import { useCurrentUser } from "hooks/useCurrentUser";
import LoaderComponent from "components/loader/LoaderComponent";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import { speciality } from "helpers/enum";
import { useLocation } from "react-router-dom";
import { useApiMutation } from "hooks/useApiMutation";
import {
  getPreselectedInfoAction,
  postPreselectedInfoAction,
  unservicedStateNotifyAction,
} from "api/actions/VisitActions";
import constants from "helpers/constants";
import useModal from "hooks/useModal";
import Placeholder from "components/placeholders/Placeholder";
import DownloadAppSection from "components/DownloadAppSection/DownloadAppSection";
import useIncompleteData from "hooks/visit/useIncompleteData";
import Banner from "pages/home/waitingRoom/components/banner";
import ServiceSection from "./components/ServiceSection";
import SliderSection from "./components/slider";
import Testimonials from "./components/testomonials";
import NotificationBar from "./components/NotificationBar";
import { useParameterizedQuery } from "react-fetching-library";
import TimeslotReminder from "components/TimeslotReminder/TimeslotReminder";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";

const WaitingRoom = () => {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { fetchIncompleteData } = useIncompleteData();

  const location = useLocation();
  const { state } = location;

  const userAgent = window.navigator
    ? window.navigator.userAgent
    : navigator.userAgent;

  const userPlatform = userAgent.includes("iPhone")
    ? "iphone"
    : userAgent.includes("Android")
    ? "android"
    : "web";

  const { userDetails } = useCurrentUser();

  const {
    hasNoDermaProvider,
    dermaDoctorDetails,
    pcDoctorDetails,
    hasNoProvider,
    hasActiveDermaProvidersInState,
    hasActivePcProvidersInState,
  } = useDataFromUserDetails();

  const {
    status: userDetailsStatusCode,
    payload: userDetailsFromApi,
    loading: isFetchingUser,
  } = useApiQuery(currentUserAction);
  const { mutate: callUnservicedStateNotification } = useApiMutation(
    unservicedStateNotifyAction
  );
  const {
    payload: incompleteVisitDetails,
    loading: incVisitsLoading,
    query: incQuery,
  } = useApiQuery(getIncompleteVisits, false);
  const {
    payload: preselectedInfo,
    loading: gettingPreselectedInfo,
    query: getPreselectedInfo,
  } = useApiQuery(getPreselectedInfoAction, false);
  const {
    query: postPreselectedInfo,
    loading: postingPreselectedInfo,
  } = useParameterizedQuery(postPreselectedInfoAction);
  useEffect(() => {
    if (!(isFetchingUser && userDetails) && userDetails?.incomplete_case) {
      incQuery();
    }
  }, [incQuery, userDetails?.incomplete_case, isFetchingUser, userDetails]);
  const { payload: submittedVisits } = useApiQuery(getSubmittedVisits, true);

  const ServerMaintainance = useCallback(() => {
    return (
      <div className="h-full bg-veryLightBlue">
        <div className="h-full py-20 bg-white">
          <Placeholder
            buttonLoading={isFetchingUser}
            onPressButton={() => window.location.reload()}
            buttonText="Refresh"
            placeholderImageClassName={"w-4/12"}
            placeholderImage={Images.teaCup}
            heading="Something's not right!"
            paragraph="We are sorry for the inconvinence caused. We are working on the issue
Please try after sometime or check your internet connection."
          />
        </div>
      </div>
    );
  }, [isFetchingUser]);

  useEffect(() => {
    const init = async () => {
      if (state?.from?.pathname.split("/").length === 3) {
        await postPreselectedInfo({
          preselected_slot: {
            timeslot_id: Number(state?.from?.pathname.split("/")[2]),
          },
        });
        await getPreselectedInfo();
      } else {
        await getPreselectedInfo();
      }
    };

    fetchIncompleteData(speciality.primary_care);
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      userDetailsStatusCode &&
      constants.SERVER_ERROR_CODES?.includes(userDetailsStatusCode)
    ) {
      setShowModal({
        state: true,
        children: ServerMaintainance,
      });
    }
  }, [userDetailsStatusCode, setShowModal, ServerMaintainance]);

  useEffect(() => {
    if (userDetailsFromApi) {
      dispatch(setUserDetails({ userDetails: userDetailsFromApi }));
      if (userDetails?.patient_insurance?.insurance_company?.length) {
        dispatch(
          updateInsuranceAvailability({
            isInsuranceAvailable: true,
          })
        );
      }
      dispatch(
        currentSpeciality({
          currentSpeciality: userDetails?.incomplete_case_speciality
            ? userDetails?.incomplete_case_speciality === "primary_care"
              ? speciality.primary_care
              : userDetails?.incomplete_case_speciality === "dermatology"
              ? speciality.dermatology
              : null
            : null,
        })
      );
      dispatch(
        updateVisitMessageCount({
          visitMessageCount: userDetails?.unread_messages,
        })
      );

      // Delete visit data from redux store if visit is deleted
      if (userDetailsFromApi?.incomplete_dermatology_id === null) {
        dispatch(dermaVisitFlowReducers.reset());
      }

      if (userDetailsFromApi?.incomplete_primary_care_id === null) {
        dispatch(primaryCareVisitFlowReducers.reset());
      }

      if (userDetailsFromApi?.incomplete_pediatric_id === null) {
        dispatch(pedVisitFlowReducers.reset());
      }

      if (userDetailsFromApi?.incomplete_cosmetic_consultation_id === null) {
        dispatch(cosmeticConsultationReducers.reset());
      }
    }
  }, [
    userDetailsFromApi,
    dermaDoctorDetails?.provider_id,
    hasNoProvider,
    pcDoctorDetails?.provider_id,
    userDetails?.incomplete_case_speciality,
    userDetails?.unread_messages,
    userDetails?.patient_insurance,
    dispatch,
  ]);

  useEffect(() => {
    async function init() {
      await callUnservicedStateNotification({
        speciality: !hasActiveDermaProvidersInState
          ? speciality.dermatology
          : speciality.primary_care,
      });
    }
    if (!hasActiveDermaProvidersInState || !hasActivePcProvidersInState) {
      init();
    }
  }, [
    userDetailsFromApi,
    hasActiveDermaProvidersInState,
    hasActivePcProvidersInState,
    callUnservicedStateNotification,
  ]);

  if (
    (isFetchingUser && !userDetails) ||
    postingPreselectedInfo ||
    gettingPreselectedInfo
  ) {
    return (
      <LoaderComponent
        showLoader={{ state: true, message: "Retrieving your details" }}
      />
    );
  }
  const ResponsiveView = () => {
    return (
      <div className="h-full overflow-y-auto bg-backgroundWhite">
        <NotificationBar
          leftActionView={
            <div>
              <img
                src={Images.logoHorizontalNaming}
                className="object-contain w-56 my-0 md:hidden"
                alt="logo"
              />
              <h2 className="hidden text-4xl font-bold text-black md:block">
                Patient Portal
              </h2>
            </div>
          }
          bottomActionView={
            userPlatform !== "web" && (
              <DownloadAppSection userPlatform={userPlatform} />
            )
          }
        />

        <div className="flex flex-col w-full p-6 pb-12 space-y-12 md:p-10 lg:max-w-homeScreen">
          <Banner incompleteCase={userDetails?.incomplete_case} />
          <ServiceSection
            dermaDoctorDetails={{ ...dermaDoctorDetails }}
            hasNoDermaProvider={hasNoDermaProvider}
            incVisitsLoading={incVisitsLoading}
            incompleteVisits={incompleteVisitDetails}
            submittedVisits={submittedVisits}
          />
          <SliderSection />
          <Testimonials />
        </div>
        {preselectedInfo &&
          preselectedInfo?.some(
            (info) =>
              info?.status === "unbooked" &&
              !(
                (info?.timeslot?.provider_speciality === "primary_care" &&
                  userDetails?.incomplete_primary_care_id) ||
                (info?.timeslot?.provider_speciality === "pediatric" &&
                  userDetails?.incomplete_pediatric_id)
              )
          ) && (
            <TimeslotReminder
              preselectedInfo={preselectedInfo}
              userDetails={userDetails}
              dispatch={dispatch}
            />
          )}
      </div>
    );
  };

  return (
    <div className="w-full h-full">
      {/* Desktop Responsive */}
      <ResponsiveView />
      {/* Mobile responsive */}
      {/* <div className="flex-col hidden h-full overflow-hidden bg-veryLightBlue">
        <Header
          className={"md:hidden"}
          hideBackBtn
          customView={
            <img
              src={Images.logoHorizontalNaming}
              className="object-contain w-56 mx-auto my-0"
              alt="logo"
            />
          }
        />
        {userPlatform !== "web" ? (
          <DownloadAppSection userPlatform={userPlatform} />
        ) : (
          <div />
        )}
        <div className="flex-1 px-10 py-8 overflow-y-auto bg-blue wrapper">
          <div className="mb-32">
            <MedicalAssistant content={getWaitingRoomMAText(userDetails)} />
            <Spacer height={35} />
            <div>
              {userDetails?.is_primary_care_available ||
              userDetails?.incomplete_primary_care_id ? (
                <>
                  <PCBlock
                    percentage={
                      userDetails?.incomplete_primary_care_meta
                        ?.progress_percentage
                    }
                    loading={isFetchingUser}
                    isNew={
                      userDetails?.incomplete_primary_care_id ? false : true
                    }
                    onClick={() => {
                      dispatch(
                        currentSpeciality({
                          currentSpeciality: speciality.primary_care,
                        })
                      );
                      history.push(
                        "/visit/primary-care/complete-visit-details"
                      );
                      trackEvent(
                        userDetails?.incomplete_primary_care_id
                          ? "continue_primary_care_visit"
                          : "start_primary_care_visit"
                      );
                    }}
                  />
                  <Spacer height={14} />
                </>
              ) : (
                <>
                  <NoServiceAvailable
                    subscribed={userDetails?.subscribe_to_pcp}
                    specialityValue={speciality.primary_care}
                  />
                  <Spacer height={14} />
                </>
              )}
              <DermBlock
                percentage={
                  userDetails?.incomplete_dermatology_meta?.progress_percentage
                }
                loading={isFetchingUser}
                isNew={userDetails?.incomplete_dermatology_id ? false : true}
                onClick={() => {
                  if (userDetails?.incomplete_dermatology_id) {
                    dispatch(
                      currentSpeciality({
                        currentSpeciality: speciality.dermatology,
                      })
                    );
                    history.push("/visit/dermatology");
                  } else {
                    dispatch(
                      currentSpeciality({
                        currentSpeciality: speciality.dermatology,
                      })
                    );
                    if (hasNoDermaProvider) {
                      history.push("/visit/medical-intake");
                    } else {
                      history.push("/visit/dermatology");
                    }
                    trackEvent(
                      userDetails?.incomplete_dermatology_id
                        ? "continue_dermatology_visit"
                        : "start_dermatology_visit"
                    );
                  }
                }}
              />
              <Spacer height={14} />
              {userDetails?.is_pediatric_available ||
              userDetails?.incomplete_pediatric_id ? (
                <>
                  <PedBlock
                    percentage={
                      userDetails?.incomplete_pediatric_meta
                        ?.progress_percentage
                    }
                    loading={isFetchingUser}
                    isNew={userDetails?.incomplete_pediatric_id ? false : true}
                    onClick={() => {
                      dispatch(
                        currentSpeciality({
                          currentSpeciality: speciality.ped,
                        })
                      );
                      history.push("/visit/pediatrics/complete-visit-details");
                      trackEvent(
                        userDetails?.incomplete_pediatric_id
                          ? "continue_pediatric_visit"
                          : "start_pediatric_visit"
                      );
                    }}
                  />
                  <Spacer height={14} />
                </>
              ) : (
                <>
                  <NoServiceAvailable
                    subscribed={userDetails?.subscribe_to_pediatric}
                    specialityValue={speciality.ped}
                  />
                  <Spacer height={14} />
                </>
              )}
              {userDetails?.is_cosmetic_consultation_available ||
              userDetails?.incomplete_cosmetic_consultation_id ? (
                <>
                  <CosmeticBlock
                    percentage={
                      userDetails?.incomplete_cosmetic_consultation_meta
                        ?.progress_percentage
                    }
                    loading={isFetchingUser}
                    isNew={
                      userDetails?.incomplete_cosmetic_consultation_id
                        ? false
                        : true
                    }
                    onClick={() => {
                      history.push("/consultation");
                      trackEvent(
                        userDetails?.incomplete_cosmetic_consultation_id
                          ? "continue_cosmetic_visit"
                          : "start_cosmetic_visit"
                      );
                    }}
                  />
                  <Spacer height={14} />
                </>
              ) : (
                <>
                  <NoServiceAvailable
                    subscribed={userDetails?.subscribe_to_cosmetic}
                    specialityValue={speciality.cosmetic}
                  />
                  <Spacer height={14} />
                </>
              )}
              {isMessageHidden ? null : (
                <MessageBlock onClick={() => history.push("/home/my-visits")} />
              )}
            </div>
            {hasNoProvider ? null : (
              <>
                <Spacer height={14} />
                <DermaProviderBlock
                  details={{ ...dermaDoctorDetails }}
                  onChangeProviderClick={() =>
                    history.push(`/change-provider/${speciality.dermatology}`)
                  }
                />
                <Spacer height={14} />
              </>
            )}
          </div>
        </div>
        {preselectedInfo &&
          preselectedInfo?.some(
            (info) =>
              info?.status === "unbooked" &&
              !(
                (info?.timeslot?.provider_speciality === "primary_care" &&
                  userDetails?.incomplete_primary_care_id) ||
                (info?.timeslot?.provider_speciality === "pediatric" &&
                  userDetails?.incomplete_pediatric_id)
              )
          ) && (
            <TimeslotReminderMobile
              preselectedInfo={preselectedInfo}
              userDetails={userDetails}
              dispatch={dispatch}
            />
          )}
      </div> */}
    </div>
  );
};

export default WaitingRoom;
