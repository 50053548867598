import { ped_sub_steps } from "helpers/enum";
import ConfirmResidenceForPed from "./basicDetails/ConfirmResidenceForPed";
import IdentityConfirmationForPed from "./basicDetails/IdentityConfirmationForPed";
import SelectConditionForPed from "./visitDetails/SelectConditionForPed";
import SymptomsForPed from "./visitDetails/SymptomsForPed";
import SelectPharmacyForPed from "./additionalDetails/SelectPharmacyForPed";
import AdditionalInfo from "./additionalDetails/AdditionalInfo";
import SelectSlot from "./selectSlot/SelectSlot";
import SelectPatientForPed from "./basicDetails/SelectPatientForPed";

export const getPedStepScreen = (currentScreen) => {
  switch (currentScreen) {
    case ped_sub_steps.select_patient:
      return <SelectPatientForPed />;
    case ped_sub_steps.confirm_residence:
      return <ConfirmResidenceForPed />;
    case ped_sub_steps.identity_confirmation:
      return <IdentityConfirmationForPed />;
    case ped_sub_steps.select_condition:
      return <SelectConditionForPed />;
    case ped_sub_steps.symptoms:
      return <SymptomsForPed />;
    case ped_sub_steps.select_slot:
      return <SelectSlot />;
    case ped_sub_steps.select_pharmacy:
      return <SelectPharmacyForPed />;
    case ped_sub_steps.additional_info:
      return <AdditionalInfo />;
    default:
      break;
  }
};
