import React from "react";

import Images from "assets/images";
import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import Button from "components/buttons/Button";

import { loginAction } from "api/actions/AuthActions";

import { useApiMutation } from "hooks/useApiMutation";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { LoginSchema } from "helpers/validationSchema";
import { signinUser } from "reducers/currentUserReducer";
import { useHistory, useLocation } from "react-router-dom";
import useToastify from "hooks/useToastify";
import FormikErrorFocus from "formik-error-focus";
import { setUserProp, trackEvent } from "helpers/analytics";
import Password from "components/inputs/Password";
import TextDivider from "components/Divider/TextDivider";
import GoogleLogin from "./GoogleLogin";

function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const { showAlert } = useToastify();
  const { mutate: callLoginApi, loading } = useApiMutation(loginAction);

  async function onFormSubmit(values) {
    try {
      const result = await callLoginApi({
        email: values.email,
        password: values.password,
      });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        successHandler(result);
      }
    } catch (err) {
      errorHandler(err);
    }
  }

  function successHandler(result) {
    dispatch(
      signinUser({
        userToken: result.payload.auth_token,
        userId: result.payload.id,
      })
    );
    setUserProp({ user_id: result.payload.id });
    if (
      state?.from?.pathname &&
      state?.from?.pathname !== "/home/profile/settings" // Handle logged out use case
    ) {
      history.replace(state?.from?.pathname);
    } else {
      history.replace("/home");
    }
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  return (
    <div className="flex flex-col justify-center h-screen bg-veryLightBlue md:bg-gradient-to-b from-blue-300 to-blue-500">
      <div className="w-full h-full mx-auto overflow-y-auto md:h-auto md:max-w-2xl bg-blue md:bg-white md:p-10 login-card md:rounded-xl">
        <div className="bg-alternativeWhite md:bg-transparent">
          <div className="flex flex-col items-center justify-center px-0 py-10 md:py-0">
            <img
              className="object-contain h-44 w-44"
              src={Images.logoVerticalNaming}
              alt="logo"
            />
            <p className="mt-6 text-4xl font-medium leading-8 md:mt-8 md:font-bold">
              Doctors anytime, anywhere
            </p>
            <p className="px-10 pt-4 pb-0 text-3xl font-medium text-center text-themeBlack md:px-0 md:pb-8 md:text-xl">
              Please enter your credentials to access your visits
            </p>
          </div>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={onFormSubmit}
        >
          {({ dirty, isValid }) => (
            <Form className="px-12 py-12 pb-32 md:px-0 md:py-0">
              <p
                style={{ lineHeight: "2.8rem" }}
                className="hidden mb-12 text-3xl font-medium text-white"
              >
                Please enter your credentials to access your visits
              </p>
              <div className="p-12 bg-white md:p-0 rounded-3xl required-label">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  id="email"
                  placeholder="johndoe@xyz.com"
                />
                <Spacer height={24} />
                <Password
                  name="password"
                  type="password"
                  label="Password"
                  id="password"
                  placeholder="Minimum 8 characters"
                  autoComplete="on"
                />
                <div
                  className="flex flex-row justify-end mt-4 text-2xl font-medium cursor-pointer text-secondaryBlue right"
                  href="/forgot-password"
                >
                  <a className="no-underline" href="/forgot-password">
                    Forgot Password
                  </a>
                </div>
                <Spacer height={35} />
                <Button
                  type="submit"
                  label={"Login"}
                  loading={loading}
                  disabled={loading || !(dirty && isValid)}
                  className={"h-18 mb-8"}
                />
                <TextDivider />
                <a href="/phone" className="no-underline ">
                  <Button
                    outlined={true}
                    onClick={() => trackEvent("phone_login")}
                    className={"h-18 my-8 text-2.5xl"}
                    type="button"
                    label={"Continue with Phone Number"}
                  />
                </a>
                <GoogleLogin source="login" />
                <div className="flex flex-col items-center justify-center ">
                  <a
                    className="hidden mt-8 text-2xl font-medium underline cursor-pointer text-indigo"
                    href="/forgot-password"
                  >
                    Forgot Password
                  </a>
                  <Spacer height={14} />
                  <p className="text-2xl font-light leading-8 text-center text-indigo">
                    Don’t have an account yet?{" "}
                    <a
                      className="text-2xl font-medium no-underline cursor-pointer text-orange"
                      href="/signup"
                    >
                      Sign up
                    </a>
                  </p>
                </div>
                <FormikErrorFocus
                  align={"bottom"}
                  ease={"linear"}
                  duration={500}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default LoginPage;
