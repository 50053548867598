import React, { useEffect, useRef, useState } from "react";
import {
  postVisitSurveyAction,
  trackSummaryViewsAction,
} from "api/actions/VisitActions";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import Images from "assets/images";
import RefillRequest from "./RefillRequest";
import moment from "moment";
import CSSLoader from "components/cssLoader/CSSLoader";
import ScrollableTabs from "./ScrollableTabs";
import DiagnosisTabContent from "./DiagnosisTabContent";
import PrescriptionMedicineTabContent from "./PrescriptionMedicineTabContent";
import OTCTabContent from "./OTCTabContent";
import ChangeShippingAddress from "./ChangeShippingAddress";

const VisitDiagnosedDetails = ({ visitDetails }) => {
  const firstLoad = useRef(true);

  const [selectedIndex, setSelectedIndex] = useState({
    index: 0,
    screen: "Diagnosis",
  });
  const [showForm, setShowForm] = useState(false);
  const [selectedStarRating, setSelectedStarRating] = useState(5);
  const [refillRequested, setRefillRequested] = useState(false);
  const [hoverState, setHoverState] = useState(null);
  const [submittedFeedback, setSubmittedFeedback] = useState(null);
  const [tabs, setTabs] = useState(["Diagnosis"]);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [orderPending, setOrderPending] = useState(false);

  const { showAlert } = useToastify();

  const { mutate: postVisitSurvey, loading: postingSurvey } = useApiMutation(
    postVisitSurveyAction
  );

  const { mutate: callTrackSummaryViews } = useApiMutation(
    trackSummaryViewsAction
  );

  useEffect(() => {
    const init = async () => {
      await callTrackSummaryViews({
        visitId: visitDetails?.id,
        payload: {
          action_name: "visit_summary",
        },
      });
    };

    if (firstLoad.current) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async () => {
      await callTrackSummaryViews({
        visitId: visitDetails?.id,
        payload: {
          action_name: "otc_action",
        },
      });
    };

    // Call track api if patient navigates to OTC tab with pending purchase
    if (
      !firstLoad.current &&
      selectedIndex.screen === "Over-the-Counter Recommendations" &&
      visitDetails?.diagnosis?.otc_medications?.some((otc) => otc?.available) &&
      !visitDetails?.diagnosis?.order
    ) {
      init();
    }
  }, [
    callTrackSummaryViews,
    selectedIndex.screen,
    visitDetails?.diagnosis?.order,
    visitDetails?.diagnosis?.otc_medications,
    visitDetails?.id,
  ]);

  useEffect(() => {
    if (orderPending) {
      setSelectedIndex({
        index: tabs?.indexOf("Over-the-Counter Recommendations"),
        screen: "Over-the-Counter Recommendations",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPending]);

  useEffect(() => {
    if (visitDetails && firstLoad.current) {
      if (visitDetails?.diagnosis?.medications.length > 0) {
        setTabs((prev) => [...prev, "Prescription Medicines"]);
      }
      if (visitDetails?.diagnosis?.otc_medications.length > 0) {
        setTabs((prev) => [...prev, "Over-the-Counter Recommendations"]);
      }
      if (
        !visitDetails?.diagnosis?.order &&
        visitDetails?.diagnosis?.otc_medications?.some((med) => med?.available)
      ) {
        setOrderPending(true);
      }
      setShippingAddress(visitDetails?.shipping_address);

      firstLoad.current = false;
    }
  }, [visitDetails]);

  const getTabContent = () => {
    switch (selectedIndex.screen) {
      case "Diagnosis":
        return <DiagnosisTabContent visitDetails={visitDetails} />;
      case "Prescription Medicines":
        return <PrescriptionMedicineTabContent visitDetails={visitDetails} />;
      case "Over-the-Counter Recommendations":
        return (
          <OTCTabContent
            visitDetails={visitDetails}
            shippingAddress={shippingAddress}
            setShowForm={setShowForm}
          />
        );
      default:
        return <DiagnosisTabContent visitDetails={visitDetails} />;
    }
  };

  const submitSurvey = async () => {
    try {
      const response = await postVisitSurvey({
        feedback: {
          recommend_rating: selectedStarRating * 2,
        },
        token: visitDetails?.evaluation_feedback_token,
      });
      if (
        response?.error ||
        response?.payload?.error ||
        response?.payload?.error_msg
      ) {
        showAlert(
          response?.payload?.error ||
            response?.payload?.error_msg ||
            "Something went wrong.",
          "error"
        );
      } else {
        setSubmittedFeedback(true);
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong.", "error");
    }
  };

  function getStars() {
    return Array(5)
      .fill("")
      .map((_, index) => {
        if (
          (typeof selectedStarRating === "number" &&
            selectedStarRating > index) ||
          (typeof hoverState === "number" && hoverState > index)
        ) {
          return Images.starFilled;
        } else {
          return Images.starUnfilled;
        }
      });
  }

  return (
    <div className="h-screen overflow-hidden bg-veryLightBlue">
      {!visitDetails?.error ? (
        <div
          className={`flex flex-col ${
            showForm ? "overflow-y-hidden" : "overflow-y-auto"
          } wrapper pt-10 bg-white h-eliminateHeader relative`}
        >
          <div className="px-12">
            <div className="flex items-center justify-between">
              <p className="text-xl font-semibold text-indigo md:text-2xl">
                DIAGNOSIS AND TREATMENT PLAN
              </p>
              <RefillRequest
                refillRequested={refillRequested}
                setRefillRequested={setRefillRequested}
                visitDetails={visitDetails}
                refillRequestSentAt={visitDetails?.refill_request_sent_at}
              />
            </div>
            <div className="flex flex-col pt-4 md:flex-row md:justify-between">
              <div className="flex items-center flex-1 p-4 space-x-8 border rounded-lg">
                <div className="w-24 h-24 p-1 border border-blue rounded-3xl">
                  <img
                    src={visitDetails?.provider_photo_url}
                    className="w-full h-full rounded-3xl"
                    alt={visitDetails?.provider_name}
                  />
                </div>
                <div className="flex flex-col ml-4">
                  <h3 className="text-3xl font-bold text-indigo">
                    {visitDetails?.provider_name}
                  </h3>
                  <p className="mt-1 text-xl font-light text-indigo">
                    reviewed this visit on{" "}
                    {moment(visitDetails?.diagnosis?.diagnosed_at).format(
                      "DD MMM, YYYY"
                    )}
                  </p>
                </div>
              </div>
              {visitDetails?.evaluation_feedback_given ? (
                <div className="flex-1" />
              ) : submittedFeedback ? (
                <div className="flex flex-col items-center flex-1 p-4 mt-4 ml-0 border rounded-lg md:mt-0 md:ml-4">
                  <img
                    className="w-16 h-16"
                    src={
                      selectedStarRating < 3
                        ? Images.sad
                        : Images.checkedLightGreen
                    }
                    alt="expression"
                  />
                  <p className="mt-6 text-xl font-light leading-8 text-center">
                    {selectedStarRating < 3
                      ? `We take your feedback very seriously and will check with the provider on what went wrong and how we can improve our quality of service the next time you give us an opportunity to serve you.`
                      : `Thank you for sharing your feedback.`}
                  </p>
                </div>
              ) : (
                <div className="flex flex-col flex-1 p-4 mt-4 ml-0 border rounded-lg md:mt-0 md:ml-4">
                  <p className="text-2xl font-light text-indigo">
                    How would you rate your experience with{" "}
                    {visitDetails?.provider_name}?
                  </p>
                  <div className="flex items-center justify-between mt-1">
                    <div className="flex mt-1">
                      {getStars().map((star, index) => {
                        return (
                          <img
                            onMouseEnter={() => setHoverState(index + 1)}
                            onMouseLeave={() => setHoverState(null)}
                            key={index}
                            onClick={() => setSelectedStarRating(index + 1)}
                            src={star}
                            className={`object-contain h-8 w-8 cursor-pointer mr-2 md:${
                              index + 1 <= hoverState &&
                              selectedStarRating < hoverState
                                ? "opacity-50"
                                : "opacity-100"
                            }`}
                            alt="rating"
                          />
                        );
                      })}
                    </div>
                    <button
                      disabled={postingSurvey || !selectedStarRating}
                      onClick={submitSurvey}
                      className={`self-end bg-transparent text-orange uppercase font-medium text-xl ${
                        postingSurvey || !selectedStarRating
                          ? "opacity-60 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {postingSurvey ? <CSSLoader className="w-8" /> : null}
                      {postingSurvey ? "Please wait" : "Submit"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ScrollableTabs
            tabs={tabs}
            orderPending={orderPending}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          <div className="flex-1 pt-6 pb-48 bg-alternativeWhite">
            {getTabContent()}
          </div>
          {showForm && (
            <ChangeShippingAddress
              patientId={visitDetails?.patient_id}
              shippingAddress={shippingAddress}
              setShippingAddress={setShippingAddress}
              setShowForm={setShowForm}
            />
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default VisitDiagnosedDetails;
