import React from "react";
import { useCosmeticConsultationFlow } from "hooks/useConsultationFlow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisitSummaryContent from "components/visitSummaryAccordion/VisitSummaryContent";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { cosmetic_steps } from "helpers/enum";
import VisitSummaryContentHeading from "components/visitSummaryAccordion/VisitSummaryContentHeading";
import Spacer from "components/Spacer/Spacer";
import ImageComponent from "components/imageComponent/ImageComponent";
import _ from "lodash";

function CosmeticPhotosSummary({ data, readOnly }) {
  const { incompleteData } = useCosmeticConsultationFlow();
  const history = useHistory();
  const dispatch = useDispatch();

  const photos = {
    close_up_1:
      data?.detail_close_up_photo_1_url ??
      incompleteData?.detail_close_up_photo_1_url,
    far_away_1:
      data?.detail_far_away_photo_1_url ??
      incompleteData?.detail_far_away_photo_1_url,
    close_up_2:
      data?.detail_close_up_photo_2_url ??
      incompleteData?.detail_close_up_photo_2_url,
    far_away_2:
      data?.detail_far_away_photo_2_url ??
      incompleteData?.detail_far_away_photo_2_url,
    close_up_3:
      data?.detail_close_up_photo_3_url ??
      incompleteData?.detail_close_up_photo_3_url,
    far_away_3:
      data?.detail_far_away_photo_3_url ??
      incompleteData?.detail_far_away_photo_3_url,
  };

  return (
    <VisitSummaryContent
      readOnly={readOnly}
      onEditClick={() => {
        dispatch(
          cosmeticConsultationReducers.currentScreen({
            value: cosmetic_steps.upload_photos,
          })
        );
        history.push("/consultation/cosmetic");
      }}
    >
      <VisitSummaryContentHeading text="Condition Photos" />
      <div className="flex pt-8 flex-wrap">
        {Object.keys(photos)?.map((el, idx) => {
          if (photos?.[el] && photos?.[el]?.length) {
            return (
              <div key={idx} className="mr-4 mb-4 flex flex-col items-center">
                <ImageComponent key={idx} url={photos?.[el]} />
                <Spacer height={4} />
                <VisitSummaryContentHeading
                  text={_.capitalize(el)?.split("_").join(" ")}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </VisitSummaryContent>
  );
}

export default CosmeticPhotosSummary;
