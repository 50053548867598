import React, { useCallback, useEffect, useState } from "react";
import { bookSlotAction, getCheckoutDetails } from "api/actions/VisitActions";
import { speciality } from "helpers/enum";
import { useApiQuery } from "hooks/useApiQuery";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import Header from "components/header/Header";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import ProviderWithPercentage from "components/providerWithPercentage/ProviderWithPercentage";
// import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import useModal from "hooks/useModal";
import InsuranceUpdateModal from "../../common/InsuranceUpdateModal";
import LoaderComponent from "components/loader/LoaderComponent";
import CardPayments from "components/payment/CardPayments";
import { useHistory } from "react-router-dom";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { currentSpeciality, setUserDetails } from "reducers/currentUserReducer";
import usePatients from "hooks/visit/usePatients";
import PrimaryCareVisitSummary from "./PrimaryCareVisitSummary";
import PromoCode from "../../common/PromoCode";
import SelectPaymentMethod from "../../common/SelectPaymentMethod";
import useUpdatePrimaryCareVisit from "hooks/visit/useUpdatePrimaryCareVisit";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import Alert from "components/alerts/Alert";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useCurrentUser } from "hooks/useCurrentUser";
import { CURRENT_SOURCE } from "helpers/currentSource";
import moment from "moment";

import { discardVisit } from "api/actions/VisitActions";

function PrimaryCareCheckout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();
  const { loading } = useVisitStepChange();
  const { getCurrentPatient } = usePatients();

  const [cards, setCards] = useState([]);
  const [allowPromoCode, setAllowPromoCode] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [promoAmount, setPromoAmount] = useState(0);
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [addingCard, setAddingCard] = useState(false);
  const [isCheckingPrice, setIsCheckingPrice] = useState(false);
  const [confirmPaymentMethod, setConfirmPaymentMethod] = useState(null);
  const [cashPrice, setCashPrice] = useState(null);
  const [insuranceBreakDown, setInsuranceBreakDown] = useState(null);

  const { userDetails } = useCurrentUser();
  // const { pcDoctorDetails } = useDataFromUserDetails();
  const {
    incompleteData,
    completedProgress,
    patientsList,
  } = usePrimaryCareVisitFlow();
  const {
    loading: isGettingCheckoutDetails,
    query: callGetCheckoutDetails,
    payload: checkoutDetails,
  } = useApiQuery(
    getCheckoutDetails(incompleteData?.id, speciality.primary_care),
    false
  );
  const { loading: bookingSlot, mutate: callBookSlot } = useApiMutation(
    bookSlotAction
  );
  const { updateVisit } = useUpdatePrimaryCareVisit();

  useEffect(() => {
    callGetCheckoutDetails();
  }, [callGetCheckoutDetails]);

  useEffect(() => {
    if (checkoutDetails) {
      if (checkoutDetails?.insurance) {
        setConfirmPaymentMethod("insurance");
      } else {
        setConfirmPaymentMethod("cash");
      }
    }
  }, [checkoutDetails, setConfirmPaymentMethod]);

  const { mutate: callDiscardVisit } = useApiMutation(discardVisit);

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(primaryCareVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.replace("/visit/type");
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, history, incompleteData?.id, userDetails]);

  function deleteVisit() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  }

  const getFinalPrice = useCallback(() => {
    if (confirmPaymentMethod === "insurance") {
      return Number(insuranceBreakDown?.final_price);
    } else if (confirmPaymentMethod === "cash") {
      if (promoAmount) {
        return Number(cashPrice) - Number(promoAmount);
      } else {
        return Number(cashPrice);
      }
    } else {
      return null;
    }
  }, [
    cashPrice,
    insuranceBreakDown?.final_price,
    confirmPaymentMethod,
    promoAmount,
  ]);

  useEffect(() => {
    if (confirmPaymentMethod) {
      if (confirmPaymentMethod === "insurance") {
        setPromoAmount(0);
        setPromoCode("");
        setAllowPromoCode(false);
        setIsPromoCodeApplied(false);
      } else if (confirmPaymentMethod === "cash") {
        setAllowPromoCode(true);
      }
    } else {
      setPromoAmount(0);
      setPromoCode("");
      setAllowPromoCode(false);
      setIsPromoCodeApplied(false);
    }
  }, [confirmPaymentMethod, getFinalPrice]);

  async function updateVisitApi() {
    try {
      const payload = {
        date: incompleteData?.meta?.booking_info?.selectedDate,
        slot_id: incompleteData?.meta?.booking_info?.slotInfo?.slot_id,
      };
      const result = await callBookSlot({
        caseId: incompleteData?.id,
        payload,
      });

      if (result && result?.error === false) {
        const currentPatient = {
          ...getCurrentPatient(incompleteData?.patient_id, patientsList),
        };
        updateVisit({
          payload: {
            case: {
              coupon_code: isPromoCodeApplied ? promoCode : null,
              payment_method:
                confirmPaymentMethod === "cash"
                  ? 0
                  : confirmPaymentMethod === "insurance"
                  ? 1
                  : null,
              status: "complete_awaiting_diagnosis",
              submitting_source: CURRENT_SOURCE,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(primaryCareVisitFlowReducers.reset());
            dispatch(currentSpeciality({ currentSpeciality: null }));
            dispatch(
              setUserDetails({
                userDetails: { ...userDetails, incomplete_visit_id: null },
              })
            );
            history.replace({
              pathname: "/visit/submitted",
              state: {
                speciality: speciality.primary_care,
                currentPatient,
                bookingInfo: incompleteData?.meta?.booking_info,
              },
            });
          },
        });
      } else {
        if (result.error || result.payload.error || result.payload.error_msg) {
          showAlert(
            result.payload.error ||
              result.payload.error_msg ||
              result.payload.message ||
              "Booking failed.",
            "error"
          );
        }
      }
    } catch (error) {}
  }

  function completeCheckout() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          onPrimaryButtonClick={() => {
            onClose();
            updateVisitApi();
          }}
          onSecondaryButtonClick={onClose}
          primaryButtonText="Confirm"
          heading="Video Appointment Confirmation"
          message={`By pressing 'Confirm', you are booking your appointment on ${moment(
            incompleteData?.meta?.booking_info?.selectedDate,
            "YYYY-MM-DD"
          ).format("MMMM DD, YYYY")} at ${
            incompleteData?.meta?.booking_info?.slotInfo?.start_time
          }.`}
        />
      ),
    });
  }

  function isButtonValid() {
    if (isCheckingPrice || !confirmPaymentMethod || checkingCoupon) {
      return false;
    } else {
      if (
        typeof getFinalPrice() === "number" &&
        getFinalPrice() === 0 &&
        cards?.length
      ) {
        return true;
      } else if (
        typeof getFinalPrice() === "number" &&
        getFinalPrice() > 0 &&
        cards?.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (isGettingCheckoutDetails || loading || bookingSlot) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: bookingSlot
            ? "Booking your slot."
            : loading
            ? "Submitting your visit. Do not press the refresh (or) go back button"
            : "Please wait",
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue overflow-hidden">
      <Header
        title="Appointment Details"
        hideBackBtn={loading}
        onBackButtonClick={() => history.replace("/visit/type")}
        rightActionView={
          <p
            onClick={deleteVisit}
            className="text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer"
          >
            Delete
          </p>
        }
      />
      <div className="h-eliminateHeaderWithFooter bg-white overflow-y-auto wrapper">
        <div className="py-8">
          <MedicalAssistant
            xOffset={23}
            content={
              <p>
                Yay! Your visit is almost complete, please select a payment
                method and submit the visit.
              </p>
            }
          />
        </div>
        <ProviderWithPercentage
          doctorDetails={{
            provider_name: incompleteData?.provider_name,
            provider_id: incompleteData?.provider_id,
            provider_photo_url: incompleteData?.provider_photo_url,
            speciality: 2,
          }}
          percentage={completedProgress}
        />
        <div className="px-12  pt-8">
          <SelectPaymentMethod
            checkoutDetails={checkoutDetails}
            cashPrice={cashPrice}
            setCashPrice={setCashPrice}
            setIsCheckingPrice={setIsCheckingPrice}
            insuranceBreakDown={insuranceBreakDown}
            setInsuranceBreakDown={setInsuranceBreakDown}
            paymentMethod={confirmPaymentMethod}
            setPaymentMethod={setConfirmPaymentMethod}
            onUpdatePress={(insurance, currentPatient, callBack) => {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <InsuranceUpdateModal
                    onClose={onClose}
                    insuranceDetails={insurance}
                    currentPatient={currentPatient}
                    currentSpeciality={speciality.primary_care}
                  />
                ),
                data: null,
              });
            }}
            currentSpeciality={speciality.primary_care}
            patientsList={patientsList}
            incompleteData={incompleteData}
          />
        </div>
        <div className="px-12 pt-8">
          <CardPayments
            amount={Math.max(getFinalPrice()).toFixed(2)}
            setAddingCard={setAddingCard}
            cards={cards}
            setCards={setCards}
            caseId={incompleteData?.id}
          />
        </div>
        {allowPromoCode && Number(cashPrice) > 0 ? (
          <div className="px-12 pb-8">
            <PromoCode
              promoAmount={promoAmount}
              amount={cashPrice}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              setPromoAmount={setPromoAmount}
              isPromoCodeApplied={isPromoCodeApplied}
              setIsPromoCodeApplied={setIsPromoCodeApplied}
              setCheckingCoupon={setCheckingCoupon}
              speciality={speciality.primary_care}
            />
          </div>
        ) : null}
        <PrimaryCareVisitSummary />
      </div>
      <BottomFixedButton
        onClick={completeCheckout}
        loading={loading || addingCard}
        label={addingCard ? "Updating payment" : "Schedule My Appointment"}
        disabled={isButtonValid() ? false : true}
      />
    </div>
  );
}

export default PrimaryCareCheckout;
