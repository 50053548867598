export const checkBasicDetailsAvailable = ({ patient }) => {
  if (
    !(patient?.street_add_1 && patient?.street_add_1.length) ||
    !(patient?.city && patient?.city.length) ||
    !(patient?.zip && patient?.zip.length)
  ) {
    return false;
  } else {
    return true;
  }
};
export const checkPatientBioAvailable = ({ patient }) => {
  if (
    !(patient?.dob && patient?.dob.length) ||
    !(patient?.gender && patient?.gender.length) ||
    !patient?.height ||
    !patient?.weight
  ) {
    return false;
  } else {
    return true;
  }
};
