import Images from "assets/images";
import classNames from "classnames";
import { insuranceServices } from "components/addInsuranceBlock/AddInsuranceBlock";
import CSSLoader from "components/cssLoader/CSSLoader";
import { trackEvent } from "helpers/analytics";
import React from "react";
import { Icon } from "stories/Icons";

function CashOption({
  isActive,
  price,
  className,
  onClick,
  disabled,
  loading,
}) {
  function getFormattedPrice(price) {
    return Number(price).toFixed(2);
    // .replace(/\.?0*$/, "");
  }

  return (
    <div
      className={classNames(
        "bg-white rounded-xl p-4 md:p-6 flex flex-col  overflow-x-hidden",
        "border-2 border-t-8 w-full  active:bg-orange ring-0 focus:ring-0 focus:shadow-none active:shadow-none text-black",
        // selected ? "text-grey-500 cursor-not-allowed" : "text-black",
        isActive ? "border-orange" : "border-grey-600",
        className
      )}
      onClick={
        disabled
          ? null
          : () => {
              onClick();
              trackEvent("select_payment_cash");
            }
      }
    >
      <div className="flex items-start w-full space-x-4">
        <div>
          <Icon
            className="cursor-pointer"
            name={isActive ? "radioActive" : "radioInactive"}
          />
        </div>
        <div className="w-full">
          <h2 className="text-2.5xl md:text-3xl font-bold pt-1 md:pt-0">
            {`Looking For Something Else?`}
            <span className="pl-2 md:pl-4 text-secondaryBlue-600">
              Self-Pay
            </span>
          </h2>
          <div className="-ml-12 md:ml-0">
            <h2 className="py-6 text-2xl font-bold">Additional Benefits</h2>
            <div className="space-y-6 md:space-y-0 md:grid items-start grid-flow-col grid-rows-3 md:pt-4 pb-2 gap-y-2.5 md:gap-x-4">
              {insuranceServices.map((e) => {
                return (
                  <div key={e} className="flex space-x-4">
                    <img
                      className="w-8 h-8"
                      src={Images.checkedGreen}
                      alt="expression"
                    />
                    <p className="text-2xl font-light leading-8 text-black ">
                      {e}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex items-center justify-between pt-6 mt-6 space-x-4 border-t border-grey-600 -ml-12 md:ml-0">
            <p className="text-2xl font-semibold">Patient Responsibility</p>
            {loading ? (
              <CSSLoader className="w-14 h-14" color="orange" />
            ) : (
              <p className="text-3xl font-semibold">
                ${price ? getFormattedPrice(price) : 0}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashOption;
