import { userLogoutSessionAction } from "api/actions/UserActions";
import Images from "assets/images";
import Alert from "components/alerts/Alert";
import DownloadAppBanner from "components/downloadApp/DownloadAppBanner";
import SimpleHeader from "components/header/SimpleHeader";
import LoaderComponent from "components/loader/LoaderComponent";
import SettingsItem from "components/settings/SettingsItem";
import constants from "helpers/constants";
import { useApiQuery } from "hooks/useApiQuery";
import useModal from "hooks/useModal";
import useToastify from "hooks/useToastify";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { cosmeticConsultationReducers } from "reducers/cosmetic/cosmeticConsultationReducer";
import { logoutUser } from "reducers/currentUserReducer";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";

const settingsData = [
  {
    title: "Contact Us",
    icon: Images.contactIcon,
    type: "default",
    key: "contact-us",
  },
  {
    title: "About Us",
    icon: Images.aboutIcon,
    type: "default",
    key: "about-us",
  },
  {
    title: "Terms & Conditions",
    icon: Images.termsAndConditionsIcon,
    type: "default",
    key: "terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    icon: Images.privacyPolicyIcon,
    type: "default",
    key: "privacy-policy",
  },
  {
    title: "FAQ",
    icon: Images.faqIcon,
    type: "default",
    key: "faq",
  },
  {
    title: "Change Password",
    icon: Images.changePasswordIcon,
    type: "default",
    key: "change-password",
  },
  {
    title: "Download SkyMD app",
    icon: Images.phone,
    type: "default",
    key: "download-app",
  },
  {
    title: "Logout",
    icon: Images.logoutIcon,
    type: "logout",
    key: "logout",
  },
];

function ProfileSettings() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();
  const { url } = useRouteMatch();
  const { query: logoutSessionAPI, loading: loggingOut } = useApiQuery(
    userLogoutSessionAction,
    false
  );

  async function logoutConfirmed() {
    try {
      const logoutResonse = await logoutSessionAPI();
      if (logoutResonse?.error === false) {
        dispatch(dermaVisitFlowReducers.reset());
        dispatch(primaryCareVisitFlowReducers.reset());
        dispatch(cosmeticConsultationReducers.reset());
        dispatch(pedVisitFlowReducers.reset());
        dispatch(logoutUser());
      } else {
        showAlert("Something went wrong", "error");
      }
    } catch (error) {
      showAlert("Something went wrong", "error");
    }
  }

  function onItemClick(setting) {
    switch (setting?.key) {
      case "faq":
        window.open(constants.FAQ, "_blank");
        break;

      case "contact-us":
        history.push(`${url}/contact-us`);
        break;

      case "change-password":
        history.push(`${url}/change-password`);
        break;

      case "about-us":
        window.open(constants.ABOUT_US_URL, "_blank");
        break;
      case "terms-and-conditions":
        window.open(constants.TERMS_AND_CONDITIONS_URL, "_blank");
        break;

      case "privacy-policy":
        window.open(constants.PRIVACY_URL, "_blank");
        break;

      case "download-app":
        setShowModal({
          state: true,
          children: (_, onClose) => (
            <DownloadAppBanner
              heading={"Download our app and get treated on the go."}
              subHeading={
                "Download our free app, and you can have access to care anytime on your smartphone. We designed our app to be your medical companion online."
              }
              showQR={true}
              modal
              onClose={onClose}
            />
          ),
        });
        break;

      case "logout":
        setShowModal({
          state: true,
          children: (_, onClose) => (
            <Alert
              heading="Logout"
              message="Are you sure you want to logout?"
              primaryButtonText="Confirm"
              secondaryButtonText="Cancel"
              onPrimaryButtonClick={() => {
                onClose();
                logoutConfirmed();
              }}
              onSecondaryButtonClick={onClose}
            />
          ),
        });
        break;

      default:
        break;
    }
  }

  if (loggingOut) {
    return (
      <LoaderComponent showLoader={{ state: true, message: "Logging out." }} />
    );
  }

  return (
    <div className="h-screen overflow-hidden bg-white">
      <SimpleHeader
        onBackButtonClick={() => {
          history.goBack();
        }}
        className="mx-2"
        title="Settings"
      />
      <div className="pr-4 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
        <div className="px-12 pb-20 pr-20">
          {settingsData?.map((setting, index) => {
            return (
              <SettingsItem
                key={index}
                itemName={setting?.title}
                iconSource={setting?.icon}
                onItemClick={() => onItemClick(setting)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
