import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { primary_care_main_steps, primary_care_sub_steps } from "helpers/enum";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";

function usePopulatePrimaryCareData({ incompleteData, patientsList }) {
  const dispatch = useDispatch();

  const updateMainSteps = useCallback(() => {
    const apiMainStepKey = incompleteData?.primary_care_steps?.main_steps;
    const mainStepsArray = [
      primary_care_main_steps.visit_details,
      primary_care_main_steps.main_select_slot,
      primary_care_main_steps.basic_info,
      primary_care_main_steps.additional_details,
    ];
    mainStepsArray.forEach((el) => {
      if (
        apiMainStepKey &&
        apiMainStepKey?.hasOwnProperty(primary_care_main_steps?.[el])
      ) {
        dispatch(
          primaryCareVisitFlowReducers?.[primary_care_main_steps?.[el]]({
            status: apiMainStepKey?.[primary_care_main_steps?.[el]],
          })
        );
      }
    });
    dispatch(
      primaryCareVisitFlowReducers.completedProgress({
        value: incompleteData?.meta?.progress_percentage,
      })
    );
  }, [
    dispatch,
    incompleteData?.primary_care_steps?.main_steps,
    incompleteData?.meta?.progress_percentage,
  ]);

  const updateVisitDetails = useCallback(() => {
    const patientId = incompleteData?.patient_id;
    let selectPatientStatus;

    const patientData = patientsList?.find(
      (patient) => patient?.id === patientId
    );

    if (patientData?.relationship_with_account === "myself") {
      selectPatientStatus = "Me";
    } else if (patientData?.relationship_with_account === "pappa") {
      selectPatientStatus = "My Child";
    } else {
      selectPatientStatus = "Someone else";
    }

    dispatch(
      primaryCareVisitFlowReducers.select_patient({
        value: selectPatientStatus,
        isValid: selectPatientStatus ? true : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.select_patient
          ] ?? "upcoming",
      })
    );
    if (selectPatientStatus === "My Child") {
      dispatch(
        primaryCareVisitFlowReducers.select_child({
          value: patientData?.full_name,
        })
      );
    } else if (selectPatientStatus === "Someone else") {
      dispatch(
        primaryCareVisitFlowReducers.select_someone_else({
          value: patientData?.full_name,
        })
      );
    }
    dispatch(
      primaryCareVisitFlowReducers.setPaymentMethod({
        value: incompleteData?.payment_method,
      })
    );
    dispatch(
      primaryCareVisitFlowReducers.select_condition({
        value: incompleteData?.skin_issue,
        isValid: incompleteData?.skin_issue?.length ? true : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.select_condition
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.primary_care_steps?.sub_steps,
    incompleteData?.skin_issue,
    incompleteData?.patient_id,
    incompleteData?.payment_method,
    patientsList,
  ]);

  const updateSlotDetails = useCallback(() => {
    dispatch(
      primaryCareVisitFlowReducers.select_slot({
        value: incompleteData?.meta?.booking_info,
        isValid:
          incompleteData?.meta?.booking_info &&
          Object.keys(incompleteData?.meta?.booking_info)?.length
            ? true
            : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.select_slot
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.meta?.booking_info,
    incompleteData?.primary_care_steps?.sub_steps,
  ]);

  const updateBasicDetails = useCallback(() => {
    dispatch(
      primaryCareVisitFlowReducers.confirm_residence({
        isValid: incompleteData?.state?.length ? true : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.confirm_residence
          ] ?? "upcoming",
      })
    );
    dispatch(
      primaryCareVisitFlowReducers.identity_confirmation({
        value: { uri: patientsList?.[0]?.insurance?.id_proof ?? "" },
        isValid: patientsList?.[0]?.insurance?.id_proof ? true : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.identity_confirmation
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.primary_care_steps?.sub_steps,
    incompleteData?.state?.length,
    patientsList,
  ]);

  const updateAdditionalDetails = useCallback(() => {
    dispatch(
      primaryCareVisitFlowReducers.select_pharmacy({
        value: incompleteData?.pharmacy_details,
        isValid: incompleteData?.pharmacy_details ? true : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.select_pharmacy
          ] ?? "upcoming",
      })
    );
    dispatch(
      primaryCareVisitFlowReducers.symptoms({
        value: incompleteData?.primary_care_symptoms,
        isValid: incompleteData?.primary_care_symptoms?.length ? true : false,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.symptoms
          ] ?? "upcoming",
      })
    );
    dispatch(
      primaryCareVisitFlowReducers.additional_info({
        value: {
          additional_info: incompleteData?.additional_info,
          additional_info_recording: incompleteData?.additional_info_recording,
        },
        isValid: true,
        status:
          incompleteData?.primary_care_steps?.sub_steps?.[
            primary_care_sub_steps.additional_info
          ] ?? "upcoming",
      })
    );
  }, [
    dispatch,
    incompleteData?.pharmacy_details,
    incompleteData?.additional_info,
    incompleteData?.additional_info_recording,
    incompleteData?.primary_care_steps?.sub_steps,
    incompleteData?.primary_care_symptoms,
  ]);

  useEffect(() => {
    if (incompleteData && Object.keys(incompleteData)?.length && patientsList) {
      updateMainSteps();
      updateVisitDetails();
      updateSlotDetails();
      updateBasicDetails();
      updateAdditionalDetails();
    }
  }, [
    incompleteData,
    patientsList,
    updateMainSteps,
    updateBasicDetails,
    updateVisitDetails,
    updateSlotDetails,
    updateAdditionalDetails,
  ]);
}

export default usePopulatePrimaryCareData;
