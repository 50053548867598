import Images from "assets/images";
import ImageComponent from "components/imageComponent/ImageComponent";
import DOMPurify from "dompurify";
import moment from "moment";
import { isEmpty, capitalize } from "lodash";
import WarehouseMedicinePurchase from "./otcFlow/WarehouseMedicinePurchase";
const ContentText = ({ label, child }) => (
  <div className="flex flex-col space-y-1 text-xl">
    <div className="font-bold">{label}</div>
    <div className="font-medium text-grey-700">
      {Array.isArray(child)
        ? child.map((e, index) => (
            <div key={index}>
              {e}
              {index !== child.length - 1 && ", "}{" "}
              {/* Add comma and space for all elements except the last one */}
            </div>
          ))
        : child}
    </div>
  </div>
);

const VisitDetails = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 mb-4 space-y-4 rounded-md bg-backgroundWhite">
    <ContentText label={"Patient Name"} child={data.patient_name} />
    <ContentText label={"State of Residence"} child={data?.state} />
    {data?.patient_photo_id && (
      <img src={data.patient_photo_id} alt="Patient Identity" />
    )}
    {data?.selected_date && (
      <ContentText
        label={"Appointment Date"}
        child={moment(data?.selected_date).format("dddd MM-DD-YYYY")}
      />
    )}
    {data.slot_timing && (
      <ContentText
        label={"Slot time"}
        child={
          <p>
            {moment(data.slot_timing, "hh:mma").format("LT")}
            {data.slot_timing_end &&
              " - " + moment(data.slot_timing_end, "hh:mma").format("LT")}
          </p>
        }
      />
    )}
    {data?.condition.length > 0 && (
      <ContentText label={"Condition"} child={data.condition} />
    )}
    {data?.previous_diagnosis && (
      <ContentText
        label={"Previous Treatment Feedback"}
        child={data.previous_diagnosis}
      />
    )}
    {data?.visit_condition && (
      <ContentText label={"Visit Condition"} child={data.visit_condition} />
    )}
    {data?.affected_parts && (
      <ContentText label={"Affected Part(s)"} child={data.affected_parts} />
    )}
    {data?.condition_status && <ContentText label={"Condition Details"} />}
    {data?.condition_status && (
      <ContentText label={"Status"} child={capitalize(data.condition_status)} />
    )}
    {data?.condition_days && (
      <ContentText label={"Days"} child={data.condition_days} />
    )}
    {data?.condition_severity && (
      <ContentText
        label={"Severity"}
        child={capitalize(data.condition_severity)}
      />
    )}
  </div>
);
const PhotoSection = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 mb-4 space-y-4 rounded-md bg-backgroundWhite">
    <ContentText label={"Case Photos"} />
    <div className="flex flex-wrap w-2/3 space-x-4">
      {data?.map((obj, idx) => {
        if (obj && obj?.data) {
          return (
            <div
              key={idx}
              className="flex flex-col items-center mb-4 mr-4 space-y-2 w-min"
            >
              <ImageComponent key={idx} url={obj?.data} />
              <ContentText label={obj?.key} />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  </div>
);

const SymptomsSection = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 mb-4 space-y-4 rounded-md bg-backgroundWhite">
    <ContentText label={"Symptoms"} />
    {data.raw.length > 0 ? (
      data.raw?.map((item, index) => (
        <div key={index}>
          <ContentText label={item.category} child={item.symptoms ?? item} />
        </div>
      ))
    ) : (
      <div className="text-xl font-medium text-grey-700">{"None"}</div>
    )}
    {data.worse && (
      <ContentText label={"What makes it worse?"} child={data.worse} />
    )}
    {data.better && (
      <ContentText label={"What makes it better?"} child={data.better} />
    )}
  </div>
);
const MedicalHistory = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 mb-4 space-y-4 rounded-md bg-backgroundWhite">
    <ContentText
      label={"Medical History"}
      child={
        data.medical_history.length > 0
          ? data.medical_history
              .filter((name) => name !== null && name !== undefined)
              .join(", ")
          : "None"
      }
    />
    <ContentText
      label={"Allergies"}
      child={data.allergies
        .filter((name) => name !== null && name !== undefined)
        .join(", ")}
    />
    <ContentText
      label={"Medications"}
      child={
        data.medications.length > 0
          ? data.medications
              .map((item) => item.name)
              .filter((name) => name !== null && name !== undefined)
              .join(", ")
          : "None"
      }
    />
    <ContentText
      label={"Skin Care Products"}
      child={
        data.skin_care_products.length > 0
          ? data.skin_care_products.join(", ")
          : "None"
      }
    />
    <ContentText
      label={"Additional Info"}
      child={data.additional_info !== null ? data.additional_info : "None"}
    />
    <ContentText
      label={"Pharmacy"}
      child={
        !isEmpty(data.pharmacy_details) ? (
          <div>
            <h2 className="text-xl font-medium text-black">
              {data.pharmacy_details.address?.split("\n")?.[0]}
            </h2>
            <p
              className="text-xl"
              dangerouslySetInnerHTML={{
                __html: data.pharmacy_details.address
                  ?.split("\n")
                  ?.filter((_, idx) => idx !== 0)
                  ?.join("\n"),
              }}
            ></p>
          </div>
        ) : (
          "None"
        )
      }
    />
  </div>
);
const CodeOfConduct = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 mb-4 space-y-4 rounded-md bg-backgroundWhite">
    <ContentText
      child={
        <div className="mr-24">
          {data?.map((term, index) => {
            return (
              <div className="flex items-center space-x-2" key={index}>
                <img
                  className="w-8 h-8"
                  src={Images.checkedOrange}
                  alt="checked"
                />
                <p
                  className="p-2 text-xl font-light leading-8"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(term),
                  }}
                />
              </div>
            );
          })}
        </div>
      }
    />
  </div>
);

const DiagnosisDetails = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 space-y-4 rounded-md bg-backgroundWhite">
    <ContentText
      label={data.diagnosis}
      child={
        <div className="flex items-start justify-start p-4 bg-white">
          <p
            className="max-w-2xl bg-white"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.diagnosis_details),
            }}
          />
        </div>
      }
    />
    <ContentText
      label={"Other Details"}
      child={
        <div className="flex items-start justify-start p-4 bg-white">
          <p
            className="max-w-2xl bg-white"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.other_details),
            }}
          />
        </div>
      }
    />
    <ContentText
      label={"Follow Up"}
      child={
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.follow_up_instruction),
          }}
        />
      }
    />
  </div>
);

const PrescriptionMedications = ({ data }) => (
  <div className="flex flex-col p-4 mx-4 space-y-4 overflow-x-auto rounded-md bg-backgroundWhite">
    <table className="m-4 border rounded-lg table-fixed border-grey-600">
      <thead>
        <tr>
          <th className="px-8 py-8 text-2.5xl font-semibold text-left bg-lightGrey text-indigo min-w-25r">
            Name of Medication
          </th>
          <th className="px-8 py-8 text-2.5xl font-semibold text-left text-white bg-secondaryBlue-500 min-w-25r">
            Instruction
          </th>
        </tr>
      </thead>
      <tbody>
        {data.medications?.map((el, index) => (
          <tr key={index}>
            <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo">
              {el?.name}
            </td>
            <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
              {el?.detail}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <p className="text-xl font-medium text-grey-700">
      {"The above prescriptions have been made available for pick up at"}
    </p>
    <ContentText
      label={"Pharmacy"}
      child={
        !isEmpty(data.pharmacy_details) ? (
          <div>
            <h2 className="text-xl font-medium text-black">
              {data.pharmacy_details.address?.split("\n")?.[0]}
            </h2>
            <p
              className="text-xl"
              dangerouslySetInnerHTML={{
                __html: data.pharmacy_details.address
                  ?.split("\n")
                  ?.filter((_, idx) => idx !== 0)
                  ?.join("\n"),
              }}
            ></p>
          </div>
        ) : (
          "None"
        )
      }
    />
  </div>
);
const PickupLocal = ({ meds }) => (
  <div>
    <div className="flex items-center px-4 py-2 ">
      <h2 className="pb-6 pl-4 text-2xl font-medium">
        <li>{"Pick up from your local store"}</li>
      </h2>
    </div>
    <div className="p-4 overflow-x-auto bg-backgroundWhite">
      <table className="m-4 border rounded-lg table-fixed border-grey-600">
        <thead>
          <tr>
            <th className="w-1/2 px-8 py-8 text-2.5xl font-semibold text-left bg-lightGrey text-indigo min-w-25r">
              Product and Instruction
            </th>
            <th className="w-1/2 px-8 py-8 text-2.5xl font-semibold text-left text-white bg-secondaryBlue-500 min-w-14.8">
              Qty.
            </th>
          </tr>
        </thead>
        <tbody>
          {meds?.map((el, index) => (
            <tr key={index}>
              <td className="w-full px-8 py-8 space-y-1 text-2xl border-b border-grey-600 bg-gray-altTwoH text-indigo ">
                <p>{el?.name}</p>
                <p className="text-xl">{el?.instruction}</p>
              </td>
              <td className="px-8 py-8 text-2xl border-b border-grey-600 bg-secondaryBlue-100 text-indigo">
                {el?.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const OTCRecommendations = ({ data }) => (
  <div className="flex flex-col justify-center space-y-4 rounded-md ">
    {data.available.medicines.length > 0 && (
      <WarehouseMedicinePurchase data={data} />
    )}
    {data.unavailable_meds.length > 0 && (
      <PickupLocal meds={data.unavailable_meds} />
    )}
  </div>
);

const ContentCard = ({ content, type }) => {
  const renderComponent = (type, content) => {
    switch (type) {
      case "visit_details":
        return <VisitDetails data={content} />;
      case "photo":
        return <PhotoSection data={content} />;
      case "symptoms":
        return <SymptomsSection data={content} />;
      case "medical_history":
        return <MedicalHistory data={content} />;
      case "coc":
        return <CodeOfConduct data={content} />;
      case "diagnosis":
        return <DiagnosisDetails data={content} />;
      case "prescription":
        return <PrescriptionMedications data={content} />;
      case "otc":
        return <OTCRecommendations data={content} />;
      default:
        return null;
    }
  };

  return <div>{renderComponent(type, content)}</div>;
};

export { ContentCard };
